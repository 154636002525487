import { defineStore } from "pinia"
import { dateAsShortenedDayOfWeekMonthAndYear, formatAsDateOnly } from "@/utils/DateTimeFormatters"
import dayjs from "dayjs"
import { JobStatus } from "@/enums/JobStatus"
import junkApi from "@/api/Junk"
import documentApi from "@/api/Document"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { errorSnackbar, errorSnackbarWithPositiveAction, infoSnackbar, persistentErrorSnackbarWithPositiveAction, successSnackbar } from "@/utils/SnackbarBuilder"
import { v4 } from "uuid"
import { useSnackbarStore } from "@/stores/Snackbar"
import { useMainStore } from "@/stores/Main"
import { JobNoteType } from "@/enums/JobNoteType"

export const useJobStore = defineStore("job", {
  state: () => ({
    // job / job details
    currentJob: {},

    customerContactsWithAddresses: {},

    leadSources: [],

    jobNotes: [],
    jobAddresses: [],
    jobEmployees: [],
    jobTrucks: [],

    activeFranchiseCapacities: [],
    activeFranchiseTaxes: [],
    activeEmployees: [],
    activeTrucks: [],

    franchisesInOperatingUnit: [],
    franchiseSatelliteOffices: [],

    timeSlots: [],
    scheduleSlotAllocations: [],

    jobStatuses: [],
    jobCompletionStatuses: [],
    abandonmentReasons: [],
    cancellationReasons: [],

    trucksAndEmployeesGlobalStartTime: undefined,
    trucksAndEmployeesGlobalEndTime: undefined,
    trucksAndEmployeesGlobalDownTime: undefined,

    // discounts
    activeDiscounts: [],
    isLoadingDiscount: false,

    // receipt
    jobDocumentReceiptInfo: {},
    jobDocumentReceiptPhrases: [],

    // overrides
    jobDocumentPhraseEmployeeProfiles: [],
    jobDocumentPhraseEmployeeProfile: {},

    // signatures
    customerRepresentativeProfiles: [],

    // receipt UI
    phraseCheckboxKey: 1,

    // payments
    jobReceiptPayments: [],

    // receipt recipients
    allEligibleReceiptRecipients: [],
    selectedEmailReceiptRecipients: [],

    // loaders
    isLoadingCustomerContactsWithAddresses: false,
    isLoadingEditContact: false,
    isLoadingDeleteContact: false,
    isLoadingCustomerAddresses: false,
    isLoadingJob: false,
    isLoadingFranchiseCapacities: false,
    isLoadingJobAddressesReorder: false,
    isLoadingJobAddresses: false,
    isLoadingJobDetails: false,
    isLoadingJobLeadSource: false,
    isLoadingFranchiseAndSatelliteOffices: false,
    isLoadingJobModifiers: false,
    isLoadingJobNotes: false,
    isLoadingJobTrucksAndEmployees: false,

    isLoadingEmployeeTips: false,
    isLoadingTipConfiguration: false,
    tipConfiguration: {},

    isLoadingCreateContact: false,
    isLoadingUpdateCustomerAddress: false,
    isLoadingUpdateCustomerDetails: false,
    isLoadingJobDocuments: false,
    isLoadingJobFullScreen: { isLoading: false, loadingText: "" },
    isLoadingDeleteCustomerAddress: false,
    isLoadingPhrase: false,
    isLoadingCustomerRepresentativeProfile: false,
    isLoadingAddPayment: false,
    isLoadingCustomerCommunication: false
  }),
  getters: {
    // lead sources
    getLeadSources() {
      return this.leadSources.slice().sort((a, b) => (a.name < b.name ? -1 : 1))
    },
    // discounts
    getActiveDiscounts() {
      return this.activeDiscounts ?? []
    },
    getSelectedJobDiscount() {
      return this.activeDiscounts.find(d => d.franchiseElectedDiscountId === this.currentJob.franchiseElectedDiscountId) ?? {}
    },
    getIsLoadingDiscount() {
      return this.isLoadingDiscount
    },

    // receipt recipients
    getAllEligibleReceiptRecipients() {
      return this.allEligibleReceiptRecipients
    },
    getSelectedEmailReceiptRecipients() {
      return this.selectedEmailReceiptRecipients
    },

    // receipts
    getJobDocumentReceiptInfo() {
      return this.jobDocumentReceiptInfo ?? {}
    },
    getJobDocumentReceiptPhrases() {
      return this.jobDocumentReceiptPhrases?.slice() ?? []
    },

    // overrides
    getJobDocumentPhraseEmployeeProfiles() {
      return this.jobDocumentPhraseEmployeeProfiles?.slice() ?? []
    },
    getJobDocumentPhraseEmployeeProfile() {
      return this.jobDocumentPhraseEmployeeProfile ?? {}
    },

    // signatures
    getCustomerRepresentativeProfiles() {
      return this.customerRepresentativeProfiles?.slice() ?? []
    },
    getPhraseCheckboxKey() {
      return this.phraseCheckboxKey
    },

    // payments
    getJobReceiptPayments() {
      return this.jobReceiptPayments?.slice() ?? []
    },

    // job details
    getCurrentJob() {
      return this.currentJob ?? {}
    },
    getCurrentJobScheduledDateOrToday() {
      return formatAsDateOnly(this.currentJob?.scheduledDate)
    },
    getIsJobStatusCreated() {
      return this.currentJob?.jobStatusId === JobStatus.CREATED.id
    },
    getIsJobStatusScheduled() {
      return this.currentJob?.jobStatusId === JobStatus.SCHEDULED.id
    },
    getIsJobStatusClaimed() {
      return this.currentJob?.jobStatusId === JobStatus.CLAIMED.id
    },
    getIsJobStatusCompleted() {
      return this.currentJob?.jobStatusId === JobStatus.COMPLETED.id
    },
    getIsJobStatusCanceled() {
      return this.currentJob?.jobStatusId === JobStatus.CANCELLED.id
    },
    getIsJobStatusClosed() {
      return this.currentJob?.jobStatusId === JobStatus.CLOSED.id
    },
    getIsJobStatusAbandoned() {
      return this.currentJob?.jobStatusId === JobStatus.ABANDONED.id
    },
    getIsJobFieldsDisabled() {
      return this.getIsJobStatusCanceled || this.getIsJobStatusClosed || this.getIsJobStatusAbandoned
    },
    getJobCompletionStatuses() {
      return this.jobCompletionStatuses.slice().filter(jobCompletionStatus => jobCompletionStatus.jobId === this.currentJob?.id) ?? []
    },
    getAbandonmentReasons() {
      return this.abandonmentReasons.slice() ?? []
    },
    getIsIndirectLaborFabDisabled() {
      return !(this.getIsJobStatusCanceled || this.getIsJobStatusClosed)
    },
    getJobStatuses() {
      return this.jobStatuses?.slice()
    },
    getCancellationReasons() {
      return this.cancellationReasons.slice() ?? []
    },
    getJobNotesOrderedByCreatedDate() {
      return this.jobNotes
        ?.slice()
        .filter(jn => jn.jobId === this.currentJob?.id)
        .sort((a, b) => new dayjs(b.createdDate) - new dayjs(a.createdDate))
    },
    getAddressesOnJob() {
      return this.jobAddresses?.slice().filter(address => address.jobId === this.currentJob?.id)
    },
    getJobSelectedOperatingUnitId() {
      return this.currentJob?.operatingUnitId
    },
    getFormattedServiceDateAsDayOfWeekMonthDayYear() {
      return dateAsShortenedDayOfWeekMonthAndYear(this.currentJob?.scheduledDate) ?? ""
    },

    // customer contacts
    getCustomerContactsWithAddresses() {
      return this.customerContactsWithAddresses
    },
    getAllCustomerContactAddresses() {
      return (
        this.customerContactsWithAddresses?.addressDTOs
          ?.slice()
          .sort((a, b) => (a.addressLine1 > b.addressLine1 ? 1 : a.addressLine1 === b.addressLine1 ? (a?.addressLine2 > b?.addressLine2 ? 1 : -1) : -1)) ?? []
      )
    },
    getAllCustomerContacts() {
      return this.customerContactsWithAddresses?.contactDTOs?.slice() ?? []
    },
    getPrimaryContactDetails() {
      return this.customerContactsWithAddresses?.contactDTOs?.find(contact => contact.isPrimary === true) ?? {}
    },

    // operating units, franchises and satellite offices
    getJobSelectedOperatingUnitUsersNotOnJob() {
      return this.activeEmployees?.slice().filter(employee => !this.jobEmployees?.some(jobEmployee => employee.id === jobEmployee.employeeId)) ?? []
    },
    getActiveFranchiseCapacities() {
      return this.activeFranchiseCapacities?.slice() ?? []
    },
    getSelectedFranchise() {
      const mainStore = useMainStore()

      return (
        mainStore.getUserActiveFranchises?.slice().find(franchise => {
          return franchise.franchiseId === this.currentJob?.franchiseId
        }) ?? {}
      )
    },
    getFranchisesInOperatingUnit() {
      return this.franchisesInOperatingUnit?.slice() ?? []
    },
    getFranchiseSatelliteOfficesInOperatingUnit() {
      return this.franchiseSatelliteOffices?.slice() ?? []
    },
    getFranchiseSatelliteOfficesInFranchise() {
      return this.franchiseSatelliteOffices?.slice().filter(satelliteOffice => {
        return satelliteOffice.franchiseId === this.currentJob?.franchiseId
      })
    },
    getFranchiseDispatchAddresses() {
      const pastFranchiseAddressesOnJob = this.jobAddresses
        ?.slice()
        .filter(address => address.jobId === this.currentJob?.id && address.isFranchiseLocation === true)
        .map(ja => {
          return {
            id: ja.addressId,
            addressLine1: ja.addressLine1,
            addressLine2: ja.addressLine2,
            city: ja.city,
            zipCode: ja.zipCode,
            stateOrProvince: ja.stateOrProvince,
            country: ja.country,
            isUserValidated: ja.isUserValidated,
            latitude: ja.latitude,
            longitude: ja.longitude
          }
        })

      // todo: Add OperatingUnitId to franchiseSatelliteOffices,
      // todo: thus allowing us to filter out all satellites addresses that are not associated to the job's operating unit
      // todo: .filter(satelliteOffice => satelliteOffice.operatingUnitId === state.currentJob.operatingUnitId)
      let allAddresses = this.franchisesInOperatingUnit
        ?.slice()
        .filter(franchise => franchise.operatingUnitId === this.currentJob?.operatingUnitId)
        .map(franchise => franchise.addressDTO)
        .concat(
          this.franchiseSatelliteOffices.map(satelliteOffice => satelliteOffice.addressDTO),
          pastFranchiseAddressesOnJob
        )

      return [...new Map(allAddresses.map(address => [address.id, address])).values()]
    },
    getSelectedSatelliteOffice() {
      return (
        this.franchiseSatelliteOffices?.slice().find(franchiseSatelliteOffice => {
          return franchiseSatelliteOffice.id === this.currentJob?.franchiseSatelliteOfficeId
        }) ?? {}
      )
    },
    getTipConfiguration() {
      return this.tipConfiguration
    },

    // taxes
    getActiveFranchiseTaxes() {
      return this.activeFranchiseTaxes?.slice().filter(aft => aft.franchiseId === this.currentJob?.franchiseId) ?? []
    },

    // time slots
    getTimeSlots() {
      return this.timeSlots?.slice() ?? []
    },
    getScheduleSlotAllocations() {
      return this.scheduleSlotAllocations?.slice() ?? []
    },

    // trucks and employees
    getActiveEmployees() {
      return this.activeEmployees.slice() ?? []
    },
    getActiveEmployeesNotOnJob() {
      return this.getActiveEmployees?.slice().filter(employee => !this.jobEmployees?.some(jobEmployee => employee.id === jobEmployee.employeeId)) ?? []
    },
    getActiveTrucks() {
      return this.activeTrucks?.slice().filter(truck => truck.operatingUnitId === this.currentJob?.operatingUnitId) ?? []
    },
    getActiveTrucksNotOnJob() {
      return this.getActiveTrucks?.slice().filter(truck => !this.jobTrucks?.some(jobTruck => truck.junkTruckId === jobTruck.truckId)) ?? []
    },
    getEmployeesOnJob() {
      return this.jobEmployees?.slice().filter(jobEmployee => jobEmployee.jobId === this.currentJob?.id) ?? []
    },
    getTrucksOnJob() {
      return this.jobTrucks?.slice().filter(jobTruck => jobTruck.jobId === this.currentJob?.id) ?? []
    },
    getTrucksAndEmployeesGlobalStartTime() {
      return this.trucksAndEmployeesGlobalStartTime
    },
    getTrucksAndEmployeesGlobalEndTime() {
      return this.trucksAndEmployeesGlobalEndTime
    },
    getTrucksAndEmployeesGlobalDownTime() {
      return this.trucksAndEmployeesGlobalDownTime
    },

    // loaders
    getIsLoadingAnyJobComponents() {
      return (
        this.isLoadingJob ||
        this.isLoadingCustomerContactsWithAddresses ||
        this.isLoadingFranchiseAndSatelliteOffices ||
        this.isLoadingFranchiseCapacities ||
        this.isLoadingJobAddressesReorder ||
        this.isLoadingJobAddresses ||
        this.isLoadingJobDetails ||
        this.isLoadingJobModifiers ||
        this.isLoadingJobNotes ||
        this.isLoadingJobTrucksAndEmployees ||
        this.isLoadingJobDocuments ||
        this.isLoadingJobLeadSource ||
        this.isLoadingDiscount ||
        this.isLoadingCustomerCommunication
      )
    },
    getIsLoadingEmployeeTips() {
      return this.isLoadingEmployeeTips ?? false
    },
    getIsLoadingTipConfiguration() {
      return this.isLoadingTipConfiguration
    },
    getIsLoadingJob() {
      return this.isLoadingJob ?? false
    },
    getIsLoadingEditContact() {
      return this.isLoadingEditContact
    },
    getIsLoadingDeleteCustomerAddress() {
      return this.isLoadingDeleteCustomerAddress
    },
    getIsLoadingDeletingContact() {
      return this.isLoadingDeleteContact
    },
    getIsLoadingCustomerContactsWithAddresses() {
      return this.isLoadingCustomerContactsWithAddresses ?? false
    },
    getIsLoadingFranchiseAndSatelliteOffices() {
      return this.isLoadingFranchiseAndSatelliteOffices ?? false
    },
    getIsLoadingFranchiseCapacities() {
      return this.isLoadingFranchiseCapacities ?? false
    },
    getIsLoadingJobAddressesReorder() {
      return this.isLoadingJobAddressesReorder ?? false
    },
    getIsLoadingJobAddresses() {
      return this.isLoadingJobAddresses ?? false
    },
    getIsLoadingJobDetails() {
      return this.isLoadingJobDetails ?? false
    },
    getIsLoadingJobModifiers() {
      return this.isLoadingJobModifiers ?? false
    },
    getIsLoadingJobNotes() {
      return this.isLoadingJobNotes ?? false
    },
    getIsLoadingTrucksAndEmployees() {
      return this.isLoadingJobTrucksAndEmployees ?? false
    },
    getIsLoadingJobFullScreen() {
      return this.isLoadingJobFullScreen ?? { isLoading: false, loadingText: "" }
    },
    getIsLoadingCustomerAddresses() {
      return this.isLoadingCustomerAddresses ?? false
    },
    getIsLoadingJobDocuments() {
      return this.isLoadingJobDocuments ?? false
    },
    getIsLoadingCreateContact() {
      return this.isLoadingCreateContact ?? false
    },
    getIsLoadingUpdateCustomerDetails() {
      return this.isLoadingUpdateCustomerDetails ?? false
    },
    getIsLoadingUpdateCustomerAddress() {
      return this.isLoadingUpdateCustomerAddress ?? false
    },
    getIsLoadingCustomerRepresentativeProfile() {
      return this.isLoadingCustomerRepresentativeProfile ?? false
    },
    getIsLoadingPhrase() {
      return this.isLoadingPhrase
    },
    getIsLoadingAddPayment() {
      return this.isLoadingAddPayment
    },
    getIsLoadingJobLeadSource() {
      return this.isLoadingJobLeadSource
    },
    getIsLoadingCustomerCommunication() {
      return this.isLoadingCustomerCommunication
    }
  },
  actions: {
    /* cross-cutting */
    async clearJobState() {
      this.setSelectedEmailReceiptRecipients([])
      this.setAllEligibleReceiptRecipients([])
      this.setJobReceiptPayments([])
      this.clearCustomerRepresentativeProfiles()
      this.clearJobDocumentPhraseEmployeeProfiles()
      this.clearJobDocumentPhraseEmployeeProfile()
      this.setJobDocumentPhrases([])
      this.setJobDocumentReceiptInfo({})
      this.setJobNotes([])
      this.setJobAddresses([])
      this.setActiveFranchiseCapacities([])
      this.setActiveTaxesInFranchise([])
      this.setEmployeesInJobOperatingUnit([])
      this.setActiveTrucks([])
      this.setActiveFranchisesInOperatingUnit([])
      this.setActiveFranchiseSatelliteOfficesInOperatingUnit([])
      this.setTimeSlots([])
      this.setJobCompletionStatuses([])
      this.setCustomerContactsWithAddresses({})
      this.setDiscounts([])
    },
    setCurrentJob(job) {
      this.currentJob = { ...job }
    },
    setIsLoadingJobFullScreen({ isLoading, loadingText }) {
      this.isLoadingJobFullScreen = { isLoading: isLoading, loadingText: loadingText }
    },
    resetIsLoadingJobFullScreen() {
      this.isLoadingJobFullScreen = { isLoading: false, loadingText: "" }
    },

    // discounts
    setDiscounts(discounts) {
      this.activeDiscounts.splice(0, this.activeDiscounts?.length ?? 0, ...discounts)
    },
    setIsLoadingDiscount(isLoading) {
      this.isLoadingDiscount = isLoading
    },
    async fetchApplicableDiscounts(jobId) {
      this.setIsLoadingDiscount(true)
      await junkApi
        .fetchDiscountsByJobId(jobId)
        .then(discounts => {
          this.setDiscounts(discounts)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(errorMessage))
        })
        .finally(() => {
          this.setIsLoadingDiscount(false)
        })
    },
    async applyJobDiscount(putJobDiscountDto) {
      this.setIsLoadingDiscount(true)
      await junkApi
        .applyDiscountToJob(putJobDiscountDto)
        .then(response => {
          this.setCurrentJob(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(errorMessage))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingDiscount(false)
        })
    },
    removeInapplicableDiscounts(franchiseId) {
      this.activeDiscounts = this.activeDiscounts.filter(d => d.franchiseId === franchiseId)
    },
    async removeJobDiscount(userId) {
      this.setIsLoadingDiscount(true)
      const deleteJobDiscountDto = {
        jobId: this.getCurrentJob.id,
        modifiedBy: userId
      }
      await junkApi
        .removeDiscountFromJob(deleteJobDiscountDto)
        .then(job => {
          this.setCurrentJob(job)
          this.removeInapplicableDiscounts(job.franchiseId)
          return Promise.resolve(job)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(errorMessage))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingDiscount(false)
        })
    },

    // receipts
    setIsLoadingJobDocuments(isLoading) {
      this.isLoadingJobDocuments = isLoading
    },
    setJobDocumentReceiptInfo(jobDocumentReceiptInfo) {
      this.jobDocumentReceiptInfo = jobDocumentReceiptInfo
    },
    setJobDocumentPhrases(phrases) {
      this.jobDocumentReceiptPhrases = [...phrases]
    },
    async createJobReceipt(createJobDocumentReceiptDto) {
      this.setIsLoadingJobDocuments(true)
      this.setJobDocumentReceiptInfo({})
      this.setJobDocumentPhrases([])
      return await documentApi
        .postJobReceiptToCreate(createJobDocumentReceiptDto)
        .then(data => {
          if (data.receiptInfoDto) {
            this.setJobDocumentReceiptInfo(data.receiptInfoDto)
          }
          if (data.jobFranchiseDocumentPhraseDtos) {
            const phraseDtos = data.jobFranchiseDocumentPhraseDtos.map(phrase => {
              return { ...phrase, isSavedToServer: true }
            })
            this.setJobDocumentPhrases(phraseDtos)
          }
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(errorMessage))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDocuments(false)
        })
    },
    async fetchJobReceipt() {
      this.setIsLoadingJobDocuments(true)
      this.setJobDocumentReceiptInfo({})
      this.setJobDocumentPhrases([])
      return await documentApi
        .fetchJobReceipt(this.getCurrentJob?.id)
        .then(data => {
          if (data.receiptInfoDto) {
            this.setJobDocumentReceiptInfo(data.receiptInfoDto)
          }
          if (data.jobFranchiseDocumentPhraseDtos) {
            const phraseDtos = data.jobFranchiseDocumentPhraseDtos.map(phrase => {
              return { ...phrase, isSavedToServer: true }
            })
            this.setJobDocumentPhrases(phraseDtos)
          }
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorMessage)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDocuments(false)
        })
    },
    async generateJobReceipt(generateJobReceiptDto) {
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Generating Statement..." })
      return await documentApi
        .postJobReceiptToGenerate(generateJobReceiptDto)
        .then(response => {
          this.setJobDocumentReceiptInfo(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(errorMessage))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    async resendJobReceipt(resendJobReceiptDto) {
      const snackbarStore = useSnackbarStore()
      this.setIsLoadingCustomerCommunication(true)
      return await documentApi
        .postJobReceiptToResend(resendJobReceiptDto)
        .then(response => {
          snackbarStore.addSnackbar(successSnackbar("STATEMENT Email Sent!"))
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Sending STATEMENT Email! ${errorMessage}`, "Retry", () => this.resendJobReceipt(resendJobReceiptDto))
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingCustomerCommunication(false))
    },

    // recipients here
    setAllEligibleReceiptRecipients(allEligibleReceiptRecipients) {
      this.allEligibleReceiptRecipients = allEligibleReceiptRecipients
    },
    async setSelectedEmailReceiptRecipients(allEligibleReceiptRecipients) {
      this.selectedEmailReceiptRecipients = allEligibleReceiptRecipients
    },
    addEligibleReceiptRecipient(recipient) {
      this.allEligibleReceiptRecipients.push(recipient)
    },
    addSelectedEmailReceiptRecipient(recipient) {
      this.selectedEmailReceiptRecipients.push(recipient)
    },
    removeSelectedEmailReceiptRecipient(recipient) {
      let index = this.selectedEmailReceiptRecipients.findIndex(r => r.listId === recipient.listId)
      if (~index) {
        this.selectedEmailReceiptRecipients.splice(index, 1)
      }
    },

    // employee profiles
    addJobDocumentPhraseEmployeeProfile(profile) {
      this.jobDocumentPhraseEmployeeProfiles.push(profile)
    },
    clearJobDocumentPhraseEmployeeProfiles() {
      this.jobDocumentPhraseEmployeeProfiles = []
    },
    setJobDocumentPhraseEmployeeProfile(profile) {
      this.jobDocumentPhraseEmployeeProfile = profile
    },
    clearJobDocumentPhraseEmployeeProfile() {
      this.jobDocumentPhraseEmployeeProfile = {}
    },
    async createJobDocumentEmployeeProfile(createEmployeeProfileDto) {
      this.setIsLoadingJobDocuments(true)
      await documentApi
        .postJobDocumentEmployeeProfileToCreate(createEmployeeProfileDto)
        .then(data => {
          this.addJobDocumentPhraseEmployeeProfile(data)
          this.setJobDocumentPhraseEmployeeProfile(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Creating Employee Profile ${errorMessageHandler(error)}}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDocuments(false)
        })
    },
    async fetchJobDocumentEmployeeProfileByEmployeeId({ employeeId, firstName, lastName }) {
      this.setIsLoadingJobDocuments(true)
      await documentApi
        .fetchJobDocumentEmployeeProfileByEmployeeId(employeeId)
        .then(data => {
          if (data !== "" && data !== undefined && data !== null) {
            this.addJobDocumentPhraseEmployeeProfile(data)
            this.setJobDocumentPhraseEmployeeProfile(data)
          } else {
            const createEmployeeProfileDto = {
              employeeProfileId: v4(),
              employeeId: employeeId,
              firstName: firstName,
              lastName: lastName
            }
            this.createJobDocumentEmployeeProfile(createEmployeeProfileDto)
          }
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Fetching Employee Profile ${errorMessageHandler(error)}}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDocuments(false)
        })
    },
    async fetchJobDocumentEmployeeProfilesFromMarkedPhrases() {
      for (const phrase of this.getJobDocumentReceiptPhrases) {
        if (phrase.employeeProfileId) {
          this.setIsLoadingJobDocuments(true)
          await documentApi
            .fetchJobDocumentEmployeeProfileByProfileId(phrase.employeeProfileId)
            .then(data => {
              if (data) {
                this.addJobDocumentPhraseEmployeeProfile(data)
              }
            })
            .catch(error => {
              const snackbarStore = useSnackbarStore()
              snackbarStore.addSnackbar(errorSnackbar(`Error Fetching Employee Profiles ${errorMessageHandler(error)}}`))
              return Promise.reject(error)
            })
            .finally(() => {
              this.setIsLoadingJobDocuments(false)
            })
        }
      }
    },

    // util
    async setPhraseCheckboxKey() {
      this.phraseCheckboxKey = this.phraseCheckboxKey === 0 ? 1 : 0
    },

    // customer representative signature profiles
    setCustomerRepresentativeProfiles(profiles) {
      this.customerRepresentativeProfiles = profiles
    },
    addCustomerRepresentativeProfile(profile) {
      this.customerRepresentativeProfiles.push(profile)
    },
    async clearCustomerRepresentativeProfiles() {
      this.customerRepresentativeProfiles = []
    },
    setIsLoadingCustomerRepresentativeProfile(isLoading) {
      this.isLoadingCustomerRepresentativeProfile = isLoading
    },
    async fetchJobDocumentCustomerRepresentativeSignatureProfilesByJobId(jobId) {
      this.setIsLoadingJobDocuments(true)
      await documentApi
        .fetchCustomerRepresentativeSignatureProfilesByJobId(jobId)
        .then(data => {
          this.setCustomerRepresentativeProfiles(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Fetching Customer Representatives ${errorMessageHandler(error)}}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDocuments(false)
        })
    },
    async saveJobDocumentCustomerRepresentativeSignatureProfile(profile) {
      this.setIsLoadingCustomerRepresentativeProfile(true)
      return await documentApi
        .postCustomerRepresentativeSignatureProfile(profile)
        .then(data => {
          this.addCustomerRepresentativeProfile(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Saving Customer Representative Signature ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingCustomerRepresentativeProfile(false)
        })
    },

    // payments
    setJobReceiptPayments(payments) {
      this.jobReceiptPayments = payments
    },
    async fetchJobReceiptPayments(jobFranchiseDocumentId) {
      this.setIsLoadingJobDocuments(true)
      await documentApi
        .fetchJobReceiptPayments(jobFranchiseDocumentId)
        .then(data => {
          this.setJobReceiptPayments(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Fetching Payments ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDocuments(false)
        })
    },
    appendJobReceiptPayment(payment) {
      this.jobReceiptPayments.push(payment)
    },
    setIsLoadingAddPayment(isLoading) {
      this.isLoadingAddPayment = isLoading
    },
    async addJobReceiptPayment(payment) {
      this.setIsLoadingAddPayment(true)
      await documentApi
        .postJobReceiptPaymentToSave(payment)
        .then(data => {
          this.appendJobReceiptPayment(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Adding Payment! ${error}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingAddPayment(false)
        })
    },
    removePaymentById(paymentId) {
      let index = this.jobReceiptPayments.findIndex(p => p.jobFranchiseDocumentPaymentId === paymentId)
      if (~index) {
        this.jobReceiptPayments.splice(index, 1)
      }
    },
    async deleteJobReceiptPayment(paymentId) {
      this.setIsLoadingAddPayment(true)
      await documentApi
        .deleteJobReceiptPayment(paymentId)
        .then(() => {
          this.removePaymentById(paymentId)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Deleting Payment ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingAddPayment(false)
        })
    },
    async updateJobDocumentPhrase(jobDocumentPhrase) {
      let index = this.jobDocumentReceiptPhrases.findIndex(jdp => jdp.jobFranchiseDocumentPhraseId === jobDocumentPhrase.jobFranchiseDocumentPhraseId)
      if (~index) {
        this.jobDocumentReceiptPhrases.splice(index, 1, jobDocumentPhrase)
      }
    },
    setIsLoadingPhrase(isLoading) {
      this.isLoadingPhrase = isLoading
    },
    async saveJobDocumentPhrase(jobDocumentPhrase) {
      this.setIsLoadingPhrase(true)
      await documentApi
        .postJobDocumentPhraseToUpdate(jobDocumentPhrase)
        .then(response => {
          const updatedPhrase = { ...response, isSavedToServer: true }
          this.updateJobDocumentPhrase(updatedPhrase)
          return Promise.resolve(updatedPhrase)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Saving Job Document Phrase! ${error}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingPhrase(false)
        })
    },
    async setEmployeesInJobOperatingUnit(users) {
      this.activeEmployees = users
    },
    setIsLoadingJobLeadSource(isLoading) {
      this.isLoadingJobLeadSource = isLoading
    },
    async putLeadSource(putLeadSourceDto) {
      this.setIsLoadingJobLeadSource(true)
      return await junkApi
        .putLeadSource(putLeadSourceDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error setting job lead source: ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobLeadSource(false)
        })
    },
    setLeadSources(leadSources) {
      this.leadSources = leadSources
    },
    async fetchLeadSources() {
      this.setIsLoadingJobLeadSource(true)
      return await junkApi
        .fetchLeadSources()
        .then(data => {
          this.setLeadSources(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error fetching job lead sources: ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobLeadSource(false)
        })
    },
    setCustomerAddresses(addresses) {
      this.customerContactsWithAddresses.addressDTOs = addresses
    },
    setIsLoadingJob(isLoading) {
      this.isLoadingJob = isLoading
    },
    setJobStatuses(jobStatuses) {
      this.jobStatuses = jobStatuses
    },
    async fetchJunkJobStatuses() {
      this.setIsLoadingJob(true)
      return await junkApi
        .fetchJunkJobStatuses()
        .then(data => {
          this.setJobStatuses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Job Statuses! ${error}`, "Retry", () => this.fetchJunkJobStatuses()))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    setJobCompletionStatuses(jobCompletionStatuses) {
      this.jobCompletionStatuses = jobCompletionStatuses
    },
    async fetchJobCompletionStatuses(jobId) {
      this.setIsLoadingJob(true)
      return await junkApi
        .fetchJobCompletionStatuses(jobId)
        .then(data => {
          this.setJobCompletionStatuses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Fetching Job Completion Statuses! ${errorMessage}`, "Retry", () => this.fetchJobCompletionStatuses(jobId))
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    async fetchJunkJobByJobIdInitial(jobId) {
      this.setAllJobComponentsLoadingState(true)
      this.setCurrentJob({})
      return await junkApi
        .fetchJunkJobByJobId(jobId)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Job! ${error}`, "Retry", () => this.fetchJunkJobByJobIdInitial(jobId)))
          this.setAllJobComponentsLoadingState(false)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    async fetchJunkJobByJobId(jobId) {
      this.setIsLoadingJob(true)
      return await junkApi
        .fetchJunkJobByJobId(jobId)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Job! ${errorMessage}`, "Retry", () => this.fetchJunkJobByJobId(jobId)))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    setAbandonmentReasons(abandonmentReasons) {
      this.abandonmentReasons = abandonmentReasons
    },
    async fetchAbandonmentReasons() {
      this.setIsLoadingJob(true)
      return await junkApi
        .fetchAbandonmentReasons()
        .then(data => {
          this.setAbandonmentReasons(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Abandonment Reasons! ${errorMessage}`, "Retry", () => this.fetchAbandonmentReasons()))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    setCancellationReasons(cancellationReasons) {
      this.cancellationReasons = cancellationReasons
    },
    async fetchCancellationReasons() {
      this.setIsLoadingJob(true)
      return await junkApi
        .fetchCancellationReasons()
        .then(data => {
          this.setCancellationReasons(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Fetching Cancellation Reasons! ${errorMessage}`, "Retry", () => this.fetchCancellationReasons())
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    setIsLoadingCustomerAddresses(isLoading) {
      this.isLoadingCustomerAddresses = isLoading
    },
    async fetchCustomerAddresses(customerId) {
      this.setIsLoadingCustomerAddresses(true)
      return await junkApi
        .fetchCustomerAddresses(customerId)
        .then(data => {
          this.setCustomerAddresses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Fetching Customer Addresses! ${errorMessage}`, "Retry", () => this.fetchCustomerAddresses(customerId))
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingCustomerAddresses(false)
        })
    },
    // communications
    setIsLoadingCustomerCommunication(isLoading) {
      this.isLoadingCustomerCommunication = isLoading
    },
    async resendConfirmationEmail(resendConfirmationEmailDto) {
      const snackbarStore = useSnackbarStore()
      this.setIsLoadingCustomerCommunication(true)
      return await junkApi
        .resendJobConfirmationEmail(this.getCurrentJob.id, resendConfirmationEmailDto)
        .then(() => {
          snackbarStore.addSnackbar(successSnackbar("CONFIRMATION Email Sent!"))
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Sending CONFIRMATION Email! ${errorMessage}`, "Retry", () => this.resendConfirmationEmail(resendConfirmationEmailDto))
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingCustomerCommunication(false))
    },
    async sendCustomerTextBookingConfirmation(postCustomerTextBookingConfirmationDto) {
      const snackbarStore = useSnackbarStore()
      this.setIsLoadingCustomerCommunication(true)
      return await junkApi
        .sendCustomerTextBookingConfirmation(postCustomerTextBookingConfirmationDto)
        .then(() => {
          snackbarStore.addSnackbar(successSnackbar("CONFIRMATION Text Sent!"))
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Sending CONFIRMATION Text! ${errorMessage}`, "Retry", () =>
              this.sendCustomerTextBookingConfirmation(postCustomerTextBookingConfirmationDto)
            )
          )
        })
        .finally(() => this.setIsLoadingCustomerCommunication(false))
    },
    async sendCustomerTextEta(postCustomerTextEtaDto) {
      const snackbarStore = useSnackbarStore()
      this.setIsLoadingCustomerCommunication(true)
      return await junkApi
        .sendCustomerTextEta(postCustomerTextEtaDto)
        .then(() => {
          snackbarStore.addSnackbar(successSnackbar("ON THE WAY Text Sent!"))
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Sending ON THE WAY Text! ${errorMessage}`, "Retry", () => this.sendCustomerTextEta(postCustomerTextEtaDto))
          )
        })
        .finally(() => this.setIsLoadingCustomerCommunication(false))
    },
    async sendCustomerTextJobStatement(postCustomerTextJobStatementDto) {
      const snackbarStore = useSnackbarStore()
      this.setIsLoadingCustomerCommunication(true)

      return await junkApi
        .sendCustomerTextJobStatement(postCustomerTextJobStatementDto)
        .then(() => {
          snackbarStore.addSnackbar(successSnackbar("STATEMENT Text Sent!"))
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Sending STATEMENT Text! ${errorMessage}`, "Retry", () =>
              this.sendCustomerTextJobStatement(postCustomerTextJobStatementDto)
            )
          )
        })
        .finally(() => this.setIsLoadingCustomerCommunication(false))
    },
    async scheduleJunkJobByJobId({ jobId, scheduleJobDto }) {
      const mainStore = useMainStore()
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Scheduling Job..." })
      return await junkApi
        .scheduleJunkJob(jobId, scheduleJobDto)
        .then(data => {
          this.setCurrentJob(data)
          mainStore.updateJobTabJobNumberByJobId(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Scheduling Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    async abandonJunkJobByJobId({ jobId, abandonJobDto }) {
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Abandoning Job..." })
      return await junkApi
        .abandonJunkJob(jobId, abandonJobDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Abandoning Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    async cancelJunkJobByJobId({ jobId, cancelJobDto }) {
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Cancelling Job..." })
      return await junkApi
        .cancelJunkJob(jobId, cancelJobDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Cancelling Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    async closeJunkJobByJobId({ jobId, closeJobDto }) {
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Closing Job..." })
      return await junkApi
        .closeJunkJob(jobId, closeJobDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Closing Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    async claimJunkJobByJobId({ jobId, claimJobDto }) {
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Claiming Job..." })
      return await junkApi
        .claimJunkJob(jobId, claimJobDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Claiming Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    async disclaimJunkJobByJobId({ jobId, disclaimJobDto }) {
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Disclaiming Job..." })
      return await junkApi
        .disclaimJunkJob(jobId, disclaimJobDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Disclaiming Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    async uncompleteJunkJobByJobId(uncompleteJobDto) {
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Uncompleting Job..." })
      return await junkApi
        .uncompleteJunkJob(uncompleteJobDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Uncompleting Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    async completeJunkJobByJobId({ jobId, completeJobDto }) {
      this.setIsLoadingJobFullScreen({ isLoading: true, loadingText: "Completing Job..." })
      return await junkApi
        .completeJunkJob(jobId, completeJobDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Completing Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.resetIsLoadingJobFullScreen()
        })
    },
    setIsLoadingJobAddresses(isLoading) {
      this.isLoadingJobAddresses = isLoading
    },
    addCustomerAddress(address) {
      this.customerContactsWithAddresses.addressDTOs.push(address)
    },
    // left off here
    async createAddressRecords({ customerId, createCustomerAddressDto }) {
      this.setIsLoadingJobAddresses(true)
      return await junkApi
        .addAddress(customerId, createCustomerAddressDto)
        .then(data => {
          this.addCustomerAddress(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Adding Address! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobAddresses(false)
        })
    },
    setActiveFranchisesInOperatingUnit(activeFranchisesInOperatingUnit) {
      this.franchisesInOperatingUnit = activeFranchisesInOperatingUnit
    },
    setActiveFranchiseSatelliteOfficesInOperatingUnit(activeFranchiseSatelliteOfficesInOperatingUnit) {
      this.franchiseSatelliteOffices = activeFranchiseSatelliteOfficesInOperatingUnit
    },
    setIsLoadingFranchiseAndSatelliteOffices(isLoading) {
      this.isLoadingFranchiseAndSatelliteOffices = isLoading
    },
    async fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits(operatingUnitIds) {
      this.setActiveFranchisesInOperatingUnit([])
      this.setActiveFranchiseSatelliteOfficesInOperatingUnit([])
      this.setIsLoadingFranchiseAndSatelliteOffices(true)
      return await junkApi
        .fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits(operatingUnitIds)
        .then(data => {
          // filter here?
          this.setActiveFranchisesInOperatingUnit(data.franchiseWithAddressDTOs)
          this.setActiveFranchiseSatelliteOfficesInOperatingUnit(data.satelliteOfficeWithAddressDTOs)
          // commit("SET_ACTIVE_FRANCHISES_IN_OPERATING_UNIT", data.franchiseWithAddressDTOs)
          // commit("SET_ACTIVE_FRANCHISE_SATELLITE_OFFICES_IN_OPERATING_UNIT", data.satelliteOfficeWithAddressDTOs)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchises and Satellite Offices! ${error}`, "Retry", () =>
              this.fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits(operatingUnitIds)
            )
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseAndSatelliteOffices(false)
        })
    },
    setIsLoadingJobNotes(isLoading) {
      this.isLoadingJobNotes = isLoading
    },
    setJobNotes(jobNotes) {
      this.jobNotes = jobNotes
    },
    async fetchJunkJobNotes(jobId) {
      this.setIsLoadingJobNotes(true)
      return await junkApi
        .fetchJunkJobNotes(jobId)
        .then(data => {
          this.setJobNotes(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Notes! ${error}`, "Retry", () => this.fetchJunkJobNotes(jobId)))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobNotes(false)
        })
    },
    addJobNote(jobNote) {
      this.jobNotes.push(jobNote)
    },
    async createJunkJobNote(createJobNoteDto) {
      this.setIsLoadingJobNotes(true)
      return await junkApi
        .createJunkJobNote(createJobNoteDto)
        .then(data => {
          this.addJobNote(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Creating Note! ${error}`, "Retry", () => this.createJunkJobNote(createJobNoteDto)))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobNotes(false)
        })
    },
    updateJobNote(jobNote) {
      let index = this.jobNotes.findIndex(jn => jn.id === jobNote.id && jn.noteType === JobNoteType.JOB_NOTE)
      if (~index) {
        this.jobNotes.splice(index, 1, jobNote)
      }
    },
    async softDeleteJunkJobNote(jobNoteId) {
      this.setIsLoadingJobNotes(true)
      return await junkApi
        .softDeleteJunkJobNote(jobNoteId)
        .then(jobNote => {
          this.updateJobNote(jobNote)
          return Promise.resolve(jobNote)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(errorMessage))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobNotes(false)
        })
    },
    async updateScheduledDate(jobScheduledDateDto) {
      this.setIsLoadingJob(true)
      return await junkApi
        .updateJunkJobScheduledDate(jobScheduledDateDto)
        .then(data => {
          this.setCurrentJob(data)
          this.fetchApplicableDiscounts(data.id)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Updating Scheduled Date! ${errorMessage}`, "Retry", () => this.updateScheduledDate(jobScheduledDateDto))
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    async updateSelectedFranchiseAndCapacityByFranchiseIdAndCapacityId(putJunkJobDto) {
      const snackbarStore = useSnackbarStore()
      this.setIsLoadingFranchiseAndSatelliteOffices(true)
      return await junkApi
        .updateJunkJobSelectedFranchise(putJunkJobDto)
        .then(data => {
          if (formatAsDateOnly(data.scheduledDate) !== formatAsDateOnly(this.getCurrentJobScheduledDateOrToday)) {
            snackbarStore.addSnackbar(infoSnackbar("Updated Scheduled Date to match Selected Franchise's First Day of Business"))
          }
          this.setCurrentJob(data)
          const mainStore = useMainStore()
          mainStore.updateJobTabJobNumberByJobId(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () =>
              this.updateSelectedFranchiseAndCapacityByFranchiseIdAndCapacityId(putJunkJobDto)
            )
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseAndSatelliteOffices(false)
        })
    },
    async updateJunkJobSelectedFranchiseSatelliteOffice(putJunkJobSatelliteOfficeDto) {
      this.setIsLoadingFranchiseAndSatelliteOffices(true)
      return await junkApi
        .updateJunkJobSelectedFranchiseSatelliteOffice(putJunkJobSatelliteOfficeDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () =>
              this.updateJunkJobSelectedFranchiseSatelliteOffice(putJunkJobSatelliteOfficeDto)
            )
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseAndSatelliteOffices(false)
        })
    },
    async updateJunkJobPrices(jobPriceDto) {
      this.setIsLoadingJob(true)
      return await junkApi
        .updateJunkJobPrices(jobPriceDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.updateJunkJobPrices(jobPriceDto)))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    setIsSingleItem(isSingleItem) {
      this.currentJob.isSingleItem = isSingleItem
    },
    async updateJunkJobSingleItemStatus(singleItemDto) {
      this.setIsLoadingJob(true)
      return await junkApi
        .updateJunkJobIsSingleItem(singleItemDto)
        .then(data => {
          this.setIsSingleItem(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.updateJunkJobSingleItemStatus(singleItemDto)))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJob(false)
        })
    },
    // Customer/ Contact
    setIsLoadingDeleteCustomerAddress(isLoading) {
      this.isLoadingDeleteCustomerAddress = isLoading
    },
    removeAddressById(addressId) {
      this.customerContactsWithAddresses.addressDTOs = this.customerContactsWithAddresses.addressDTOs.filter(s => {
        return s.id !== addressId
      })
    },
    async deleteCustomerAddress(addressId) {
      this.setIsLoadingDeleteCustomerAddress(true)
      return await junkApi
        .deleteCustomerAddress(this.getCurrentJob?.customerId, addressId)
        .then(() => {
          this.removeAddressById(addressId)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDeleteCustomerAddress(false))
    },
    setIsLoadingCreateContact(isLoading) {
      this.isLoadingCreateContact = isLoading
    },
    addCustomerContact(customerContact) {
      this.customerContactsWithAddresses.contactDTOs.push(customerContact)
    },
    async createContact(createContactDto) {
      this.setIsLoadingCreateContact(true)
      return await junkApi
        .createContact(createContactDto)
        .then(customerContact => {
          this.addCustomerContact(customerContact)
          return Promise.resolve(customerContact)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingCreateContact(false))
    },
    setIsLoadingDeleteContact(isDeleting) {
      this.isLoadingDeleteContact = isDeleting
    },
    removeContact(id) {
      // this was a mutation, DELETE_CONTACT
      let index = this.customerContactsWithAddresses.contactDTOs.findIndex(c => c.id === id)
      if (~index) {
        this.customerContactsWithAddresses.contactDTOs.splice(index, 1)
      }
    },
    async deleteContact(id) {
      this.setIsLoadingDeleteContact(true)
      return await junkApi
        .deleteContact(id)
        .then(data => {
          this.removeContact(id)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDeleteContact(false))
    },
    setIsLoadingEditContact(isLoading) {
      this.isLoadingEditContact = isLoading
    },
    setCustomerContacts(customerContacts) {
      this.customerContactsWithAddresses.contactDTOs.splice(0, this.customerContactsWithAddresses.contactDTOs?.length ?? 0, ...customerContacts)
    },
    async editContact(editContactDto) {
      const mainStore = useMainStore()
      this.setIsLoadingEditContact(true)
      return await junkApi
        .editContact(editContactDto, mainStore.getUserOperatingUnitIds)
        .then(data => {
          this.setCustomerContacts(data)
          let primaryContact = data.find(c => c.isPrimary === true)
          mainStore.updateJobTabPrimaryContactByCustomerId(primaryContact)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Editing Contact ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingEditContact(false))
    },
    setIsLoadingUpdateCustomerAddress(isLoading) {
      this.isLoadingUpdateCustomerAddress = isLoading
    },
    async updateCustomerAddressUserValidated(putCustomerAddressUserValidatedDto) {
      this.setIsLoadingUpdateCustomerAddress(true)
      return await junkApi
        .updateCustomerAddressUserValidated(putCustomerAddressUserValidatedDto)
        .then(customerAddresses => {
          this.setCustomerAddresses(customerAddresses)
          return Promise.resolve(customerAddresses)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Customer Address! ${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => this.setIsLoadingUpdateCustomerAddress(false))
    },
    setIsLoadingUpdateCustomerDetails(isLoading) {
      this.isLoadingUpdateCustomerDetails = isLoading
    },
    setCustomerDetails(customerDetails) {
      // name clash - was mutation UPDATE_CUSTOMER_DETAILS
      this.customerContactsWithAddresses = Object.assign(
        {},
        {
          ...this.customerContactsWithAddresses,
          businessName: customerDetails.businessName,
          isTaxExempt: customerDetails.isTaxExempt,
          isBusiness: customerDetails.isBusiness,
          employerIdentificationNumber: customerDetails.employerIdentificationNumber,
          isCharity: customerDetails.isCharity
        }
      )
    },
    async updateCustomerDetails(putCustomerDetailsDto) {
      console.log("updateCustomerDetails has been called!")
      const mainStore = useMainStore()
      this.setIsLoadingUpdateCustomerDetails(true)
      return await junkApi
        .updateCustomerDetails(putCustomerDetailsDto)
        .then(customerDetails => {
          this.setCustomerDetails(customerDetails)
          mainStore.updateJobTabCustomerByCustomerId(customerDetails)
          return Promise.resolve(customerDetails)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => this.setIsLoadingUpdateCustomerDetails(false))
    },
    async updateCustomerAddress(putCustomerAddressDto) {
      this.setIsLoadingUpdateCustomerAddress(true)
      return await junkApi
        .updateCustomerAddress(putCustomerAddressDto)
        .then(customerAddresses => {
          this.setCustomerAddresses(customerAddresses)
          return Promise.resolve(customerAddresses)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Customer Address! ${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => this.setIsLoadingUpdateCustomerAddress(false))
    },
    setIsLoadingCustomerContactsWithAddresses(isLoading) {
      this.isLoadingCustomerContactsWithAddresses = isLoading
    },
    setCustomerContactsWithAddresses(customerContactsWithAddresses) {
      this.customerContactsWithAddresses = customerContactsWithAddresses
    },
    async fetchCustomerContactsAndAddresses(customerId) {
      const mainStore = useMainStore()
      this.setIsLoadingCustomerContactsWithAddresses(true)
      this.setCustomerContactsWithAddresses({})
      return await junkApi
        .fetchCustomerContactsAndAddresses(customerId, mainStore.getUserOperatingUnitIds)
        .then(data => {
          this.setCustomerContactsWithAddresses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`${error}`, "Retry", () => this.fetchCustomerContactsAndAddresses(customerId)))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingCustomerContactsWithAddresses(false)
        })
    },
    setIsLoadingFranchiseCapacities(isLoading) {
      this.isLoadingFranchiseCapacities = isLoading
    },
    setActiveFranchiseCapacities(activeFranchiseCapacities) {
      this.activeFranchiseCapacities = activeFranchiseCapacities
    },
    async fetchActiveFranchiseCapacitiesByFranchiseIdAndDate({ franchiseId: franchiseId, date: date }) {
      this.setIsLoadingFranchiseCapacities(true)
      this.setActiveFranchiseCapacities([])
      return await junkApi
        .fetchActiveFranchiseCapacitiesByFranchiseIdAndDate(franchiseId, date)
        .then(data => {
          this.setActiveFranchiseCapacities(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchise Capacities! ${error}`, "Retry", () =>
              this.fetchActiveFranchiseCapacitiesByFranchiseIdAndDate({ franchiseId: franchiseId, date: date })
            )
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseCapacities(false)
        })
    },
    setIsLoadingJobModifiers(isLoading) {
      this.isLoadingJobModifiers = isLoading
    },
    setActiveTaxesInFranchise(activeFranchiseTaxes) {
      this.activeFranchiseTaxes = activeFranchiseTaxes
    },
    async fetchActiveFranchiseTaxes({ franchiseId, date }) {
      this.setIsLoadingJobModifiers(true)
      return await junkApi
        .fetchActiveFranchiseTaxesByFranchiseIdAndDate(franchiseId, date)
        .then(activeFranchiseTaxes => {
          this.setActiveTaxesInFranchise(activeFranchiseTaxes)
          return Promise.resolve(activeFranchiseTaxes)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () =>
              this.fetchActiveFranchiseTaxes({
                franchiseId,
                date
              })
            )
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobModifiers(false))
    },
    async updateJobSelectedFranchiseTax(putJobSelectedFranchiseTaxDto) {
      this.setIsLoadingJobModifiers(true)
      return await junkApi
        .updateJobSelectedFranchiseTax(putJobSelectedFranchiseTaxDto)
        .then(job => {
          this.setCurrentJob(job)
          return Promise.resolve(job)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.updateJobSelectedFranchiseTax(putJobSelectedFranchiseTaxDto))
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobModifiers(false))
    },
    setIsLoadingJobDetails(isLoading) {
      this.isLoadingJobDetails = isLoading
    },
    setScheduleSlotAllocations(scheduleSlotAllocations) {
      this.scheduleSlotAllocations = scheduleSlotAllocations
    },
    async fetchScheduleSlotAllocations(getScheduleSlotAllocationsDto) {
      this.setIsLoadingJobDetails(true)
      return await junkApi
        .fetchScheduleSlotAllocations(getScheduleSlotAllocationsDto)
        .then(data => {
          this.setScheduleSlotAllocations(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.fetchScheduleSlotAllocations(getScheduleSlotAllocationsDto))
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobDetails(false))
    },
    async fetchOverSlotAllocation(dto) {
      this.setIsLoadingJobDetails(true)
      return await junkApi
        .validateJobSlotAvailability(dto)
        .then(data => {
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.fetchOverSlotAllocation(dto)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobDetails(false))
    },
    async putJobAmSlotCount(putJobSlotCountDto) {
      this.setIsLoadingJobDetails(true)
      return await junkApi
        .putJobAmSlotCount(putJobSlotCountDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.putJobAmSlotCount(putJobSlotCountDto)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobDetails(false))
    },
    async putJobPmSlotCount(putJobSlotCountDto) {
      this.setIsLoadingJobDetails(true)
      return await junkApi
        .putJobPmSlotCount(putJobSlotCountDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(errorMessageHandler(error), "Retry", () => this.putJobPmSlotCount(putJobSlotCountDto)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobDetails(false))
    },
    setTimeSlots(timeSlots) {
      this.timeSlots = timeSlots
    },
    async fetchTimeSlots() {
      this.setIsLoadingJobDetails(true)
      return await junkApi
        .fetchTimeSlots()
        .then(data => {
          this.setTimeSlots(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(errorMessageHandler(error), "Retry", () => this.fetchTimeSlots()))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobDetails(false))
    },
    setJobAddresses(jobAddresses) {
      this.jobAddresses = jobAddresses
    },
    async fetchJunkJobAddresses(jobId) {
      this.setIsLoadingJobAddresses(true)
      return await junkApi
        .fetchJunkJobAddresses(jobId)
        .then(data => {
          this.setJobAddresses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Addresses on Job! ${error}`, "Retry", () => this.fetchJunkJobAddresses(jobId)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobAddresses(false))
    },
    async addJunkJobAddresses(addresses) {
      this.setIsLoadingJobAddresses(true)
      return await junkApi
        .addJunkJobAddresses(this.getCurrentJob?.id, addresses)
        .then(data => {
          this.setJobAddresses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobAddresses(false)
        })
    },
    async deleteJunkJobAddress(jobAddressId) {
      this.setIsLoadingJobAddresses(true)
      return await junkApi
        .deleteJunkJobAddress(jobAddressId)
        .then(data => {
          this.setJobAddresses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.deleteJunkJobAddress(jobAddressId)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobAddresses(false))
    },
    setIsLoadingJobAddressesReorder(isLoading) {
      this.isLoadingJobAddressesReorder = isLoading
    },
    async updateJunkJobAddressStopOrder({ jobAddressId, desiredStopOrder }) {
      this.setIsLoadingJobAddressesReorder(true)
      await junkApi
        .updateJunkJobAddressStopOrder(jobAddressId, desiredStopOrder)
        .then(data => {
          this.setJobAddresses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Adjusting Stop Order! ${error}`, "Retry", () =>
              this.updateJunkJobAddressStopOrder({
                jobAddressId,
                desiredStopOrder
              })
            )
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobAddressesReorder(false))
    },
    async updateJunkJobStartOrReturnAddress({ jobAddressId, addressId }) {
      this.setIsLoadingJobAddresses(true)
      await junkApi
        .updateJunkJobStartOrReturnAddress(jobAddressId, addressId)
        .then(data => {
          this.setJobAddresses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () =>
              this.updateJunkJobStartOrReturnAddress({
                jobAddressId,
                addressId
              })
            )
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobAddresses(false))
    },
    async updateJunkJobPreferredTimeSlot(putJunkJobPreferredTimeSlotDto) {
      this.setIsLoadingJobDetails(true)
      return await junkApi
        .updateJunkJobPreferredTimeSlot(putJunkJobPreferredTimeSlotDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`${errorMessage}`, "Retry", () => this.updateJunkJobPreferredTimeSlot(putJunkJobPreferredTimeSlotDto))
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobDetails(false))
    },
    setIsLoadingJobTrucksAndEmployees(isLoading) {
      this.isLoadingJobTrucksAndEmployees = isLoading
    },
    setActiveTrucks(activeTrucks) {
      this.activeTrucks = activeTrucks
    },
    async fetchActiveJunkTrucksInOperatingUnitByDate({ date, operatingUnitId }) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .fetchActiveJunkTrucksInOperatingUnitByDate(date, operatingUnitId)
        .then(data => {
          this.setActiveTrucks(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Fetching Trucks! ${error}`, "Retry", () =>
              this.fetchActiveJunkTrucksInOperatingUnitByDate({
                date,
                operatingUnitId
              })
            )
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobTrucksAndEmployees(false))
    },
    setJobTrucks(jobTrucks) {
      this.jobTrucks = jobTrucks
    },
    async createJunkJobTrucks({ jobId, createJobTruckDto }) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .createJunkJobTrucks(jobId, createJobTruckDto)
        .then(data => {
          this.setJobTrucks(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Adding Trucks to Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async deleteJunkJobTruck(jobTruckId) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .deleteJunkJobTruck(jobTruckId)
        .then(data => {
          this.setJobTrucks(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Deleting Truck! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async updateJunkJobTruckStartDateTime(putJunkJobTruckStartDateTimeDto) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .updateJunkJobTruckStartDateTime(putJunkJobTruckStartDateTimeDto)
        .then(data => {
          this.setJobTrucks(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Truck Start Time! ${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async updateJunkJobTruckEndDateTime(putJunkJobTruckEndDateTimeDto) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .updateJunkJobTruckEndDateTime(putJunkJobTruckEndDateTimeDto)
        .then(data => {
          this.setJobTrucks(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Truck End Time! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async updateJunkJobTruckDownTime(updateJunkJobTruckDownTimeDto) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .updateJunkJobTruckDownTime(updateJunkJobTruckDownTimeDto)
        .then(data => {
          this.setJobTrucks(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Truck Down Time! ${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async createJunkJobEmployees({ jobId, createJobEmployeeDto }) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .createJunkJobEmployees(jobId, createJobEmployeeDto)
        .then(data => {
          this.setJobEmployees(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Adding Employees to Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    setJobEmployees(jobEmployees) {
      this.jobEmployees = jobEmployees
    },
    async createJobEmployeeIndirectLabor({ jobId, createIndirectLaborDtos }) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .createJobEmployeeIndirectLabor(jobId, createIndirectLaborDtos)
        .then(data => {
          this.setJobEmployees(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Adding Indirect Labor to Job! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async updateJunkJobEmployeeIsDriver({ jobId, putJobEmployeeIsDriverDto }) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .updateJunkJobEmployeeIsDriver(jobId, putJobEmployeeIsDriverDto)
        .then(data => {
          this.setJobEmployees(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Driver! ${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async updateJunkJobEmployeeTip({ jobEmployeeId, putJunkJobEmployeeTipDto }) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .updateJunkJobEmployeeTip(this.getCurrentJob?.id, jobEmployeeId, putJunkJobEmployeeTipDto)
        .then(data => {
          this.setJobEmployees(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Tip! ${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    setIsLoadingTipConfiguration(isLoading) {
      this.isLoadingTipConfiguration = isLoading
    },
    setTipConfiguration(tipConfiguration) {
      this.tipConfiguration = Object.assign({}, tipConfiguration)
    },
    async fetchTipConfigurationByFranchiseId(franchiseId) {
      this.setIsLoadingTipConfiguration(true)
      return await junkApi
        .fetchFranchiseTipConfigurationByFranchiseId(franchiseId)
        .then(data => {
          this.setTipConfiguration(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(
            errorSnackbarWithPositiveAction(`Error Fetching Tip Configuration! ${errorMessage}`, "Retry", () => this.fetchTipConfigurationByFranchiseId(franchiseId))
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTipConfiguration(false)
        })
    },
    setIsLoadingEmployeeTips(isLoading) {
      this.isLoadingEmployeeTips = isLoading
    },
    async updateEmployeeDistributedTips({ jobId, putEmployeeTipsDto }) {
      this.setIsLoadingEmployeeTips(true)
      return await junkApi
        .addEmployeeDistributedTipsByJobId(jobId, putEmployeeTipsDto)
        .then(data => {
          this.setCurrentJob(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Employee Tips! ${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => {
          this.setIsLoadingEmployeeTips(false)
        })
    },
    async deleteJunkJobEmployee({ jobId, jobEmployeeId }) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .deleteJunkJobEmployee(jobId, jobEmployeeId)
        .then(data => {
          this.setJobEmployees(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Deleting Employee! ${errorMessage}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async fetchJunkJobEmployees(jobId) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .fetchJunkJobEmployees(jobId)
        .then(data => {
          this.setJobEmployees(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Employees! ${error}`, "Retry", () => this.fetchJunkJobEmployees(jobId)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobTrucksAndEmployees(false))
    },
    async updateGlobalTimes(updateTrucksAndEmployeeGlobalTimesDto) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .updateGlobalTimes(updateTrucksAndEmployeeGlobalTimesDto)
        .then(jobTrucks => {
          this.setTrucksAndEmployeesGlobalTimeValues(undefined)
          this.setJobTrucks(jobTrucks)
          return Promise.resolve(jobTrucks)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Global Times! ${errorMessage}`))
          return Promise.reject(errorMessage)
        })
        .finally(() => {
          this.setIsLoadingJobTrucksAndEmployees(false)
        })
    },
    async fetchJunkJobTrucks(jobId) {
      this.setIsLoadingJobTrucksAndEmployees(true)
      return await junkApi
        .fetchJunkJobTrucks(jobId)
        .then(data => {
          this.setJobTrucks(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Trucks! ${error}`, "Retry", () => this.fetchJunkJobTrucks(jobId)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobTrucksAndEmployees(false))
    },
    setTrucksAndEmployeesGlobalStartTime(timeEvent) {
      this.trucksAndEmployeesGlobalStartTime = timeEvent
    },
    setTrucksAndEmployeesGlobalEndTime(timeEvent) {
      this.trucksAndEmployeesGlobalEndTime = timeEvent
    },
    setTrucksAndEmployeesGlobalDownTime(value) {
      this.trucksAndEmployeesGlobalDownTime = value
    },
    async setTrucksAndEmployeesGlobalTimeValues(value) {
      this.setTrucksAndEmployeesGlobalStartTime(value)
      this.setTrucksAndEmployeesGlobalEndTime(value)
      this.setTrucksAndEmployeesGlobalDownTime(value)
    },
    async setAllJobComponentsLoadingState(isLoading) {
      this.setIsLoadingFranchiseCapacities(isLoading)
      this.setIsLoadingJob(isLoading)
      this.setIsLoadingCustomerContactsWithAddresses(isLoading)
      this.setIsLoadingFranchiseAndSatelliteOffices(isLoading)
      this.setIsLoadingJobAddresses(isLoading)
      this.setIsLoadingJobDetails(isLoading)
      this.setIsLoadingJobModifiers(isLoading)
      this.setIsLoadingJobNotes(isLoading)
      this.setIsLoadingJobTrucksAndEmployees(isLoading)
      this.setIsLoadingJobLeadSource(isLoading)
    }
  }
})
