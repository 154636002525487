<template>
  <v-layout>
    <transition name="flip">
      <v-card
        ref="job-details-card-root"
        class="flex d-flex flex-column pb-2"
        v-if="isPrimaryCardDisplayed"
        :key="isPrimaryCardDisplayed"
        :loading="getIsLoadingJobDetails"
        style="min-width: 150px; min-height: 200px"
        elevation="4"
      >
        <v-card-title ref="job-details-card-primary" class="headline">
          <v-row>
            <v-col cols="auto" class="d-flex d-inline-flex flex-column pe-0">
              <div ref="job-details-card-title-job-number">{{ this.getCurrentJob.jobNumber }}</div>
            </v-col>
            <v-col>
              <div ref="job-details-card-self-scheduled-icon" v-if="isCustomerSelfScheduled" class="float-start">
                <base-tool-tip
                  ref="job-details-card-self-scheduled-tool-tip"
                  tooltipColor="blue"
                  icon-color="blue"
                  dialog-title="Customer Self-Scheduled"
                  nudge-dialog="0"
                  tooltip-icon="mdi-account-tag-outline"
                  :is-bottom-dialog="true"
                />
              </div>
            </v-col>
            <v-col ref="job-details-card-pin-container" v-if="isJobIconSetVisible" class="text-end">
              <v-row class="float-end justify-lg-space-between">
                <v-col cols="auto">
                  <v-icon ref="job-details-card-counter-icon" color="primary" large @click.stop="toggleCardDisplayed">
                    mdi-counter
                  </v-icon>
                  <base-tool-tip
                    v-if="isMultiSlotJob"
                    ref="job-details-card-tool-tip"
                    tooltipColor="red"
                    icon-color="red"
                    :dialog-title="multiSlotToolTipTitle"
                    nudge-dialog="0"
                    :tooltip-icon="slotNumberIcon"
                    :is-bottom-dialog="true"
                  />
                  <v-icon ref="job-details-card-multi-slot-icon" v-if="isMultiSlotIconVisible" color="primary" large @click.stop="toggleCardDisplayed" />
                </v-col>
                <v-col cols="auto">
                  <v-icon ref="job-details-card-pin-icon" color="primary" @click.stop="addNewJobTab">mdi-pin-outline </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="auto" class="flex-fill col-lg-6 col-md-12 d-flex">
              <v-select
                class="cursor_pointer class mb-n2"
                ref="job-details-card-preferred-time-of-day-selector"
                color="primary"
                label="Preferred Time of Day"
                item-text="name"
                item-value="id"
                :items="getTimeSlots"
                :value="getCurrentJob.preferredTimeSlotId"
                :key="preferredTimeOfDaySelectorKey"
                :disabled="getIsFieldsDisabled"
                :menu-props="{ bottom: true, offsetY: true }"
                @change="updatePreferredTimeOfDayOrSetAllocation"
                outlined
                hide-details
              />
            </v-col>
            <v-col cols="auto" class="flex-fill col-lg-6 col-md-12 d-flex">
              <v-menu
                class="elevation-0 transparent"
                ref="job-details-card-service-date-menu-selector"
                v-model="isMenuVisible"
                :close-on-content-click="false"
                transition="scale-transition"
                max-height="800px"
                max-width="800px"
                internal-activator
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="pb-4"
                    ref="job-details-card-service-date-text-field"
                    label="Scheduled Date"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    :value="getFormattedServiceDateAsDayOfWeekMonthDayYear"
                    :readonly="true"
                    :disabled="getIsFieldsDisabled"
                    :key="serviceDateTextFieldKey"
                    outlined
                    hide-details
                  />
                </template>
                <v-date-picker
                  elevation="0"
                  ref="job-details-card-date-picker"
                  color="primaryLight"
                  :disabled="getIsFieldsDisabled"
                  :value="datePickerValue"
                  :min="getMinimumPickerValue"
                  :max="maximumDate"
                  :events="slotEvents"
                  @update:picker-date="pickerUpdateHandler"
                  @input="promptIsOverAllocationDialogOrUpdateScheduledDate"
                  landscape
                  full-width
                  no-title
                >
                  <v-row class="my-1 mx-1">
                    <v-col>
                      <v-card flat outlined class="fill-height">
                        <v-card-title>Color Key</v-card-title>
                        <v-card-text class="mt-n4">
                          <v-list color="transparent" shaped>
                            <v-list-item-group ref="job-details-card-color-key-group" v-for="(item, i) in this.colorKeyItems" :key="i">
                              <v-list-item-title id="job-details-color-key-group-title" class="pt-1" v-text="item.title" />
                              <v-list-item class="ms-n2 my-md-n2" inactive disabled>
                                <v-list-item-icon class="me-2">
                                  <v-icon ref="job-details-card-color-key-icon" :color="item.color">mdi-circle-medium </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content
                                  ref="job-details-card-color-key-content"
                                  :class="`${item.color}--text pa-1 text-capitalize text-md-body-1`"
                                  v-text="item.description"
                                />
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col>
                      <v-card flat outlined class="fill-height">
                        <v-card-title class="">Order Key</v-card-title>
                        <v-card-text class="mt-n4">
                          <v-list color="transparent" shaped>
                            <v-list-item-group ref="job-details-card-order-key-group" v-for="(item, i) in this.orderKeyItems" :key="i">
                              <v-list-item-title id="job-details-order-key-group-title" class="pt-1" v-text="item.title" />
                              <v-list-item class="ms-n2 my-md-n2" inactive disabled>
                                <v-list-item-icon class="me-2">
                                  <v-icon ref="job-details-card-order-key-icon" :color="item.color">mdi-circle-medium </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content
                                  ref="job-details-card-order-key-content"
                                  :class="`${item.color}--text pa-1 text-capitalize text-md-body-1`"
                                  v-text="item.description"
                                />
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-autocomplete
            class="cursor_pointer"
            ref="job-details-card-lead-source-selector"
            color="primary"
            label="Lead Source"
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '275px' }"
            :items="getLeadSources"
            :value="getCurrentJob.leadSourceId"
            :loading="getIsLoadingJobDetails"
            :key="getCurrentJob.leadSourceId"
            :disabled="getIsLoadingJobDetails"
            @change="$emit('lead-source-updated', $event)"
            clearable
            outlined
            hide-details
          />
        </v-card-text>
      </v-card>
      <v-card ref="job-details-card-secondary" v-else style="min-width: 150px; min-height: 200px" class="flex d-flex flex-column pb-2" elevation="4">
        <v-card-title class="py-4">
          <span class="headline">Slot Allocations</span>
          <v-spacer />
          <v-icon ref="job-details-card-close-icon" large class="float-end" color="primary" @click="toggleCardDisplayed">mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                class="cursor_pointer"
                ref="job-details-card-am-slot-selector"
                color="primary"
                label="AM"
                :items="getSlotCountOptions"
                :value="getCurrentJob.amSlotCount"
                :loading="getIsLoadingJobDetails"
                :disabled="isAmSlotSelectorDisabled"
                :key="amTimeSlotSelectorKey"
                @change="promptIsOverAllocationDialogOrUpdateAmTimeSlots"
                outlined
                hide-details
              />
            </v-col>
            <v-col>
              <v-select
                class="cursor_pointer"
                ref="job-details-card-pm-slot-selector"
                color="primary"
                label="PM"
                :items="getSlotCountOptions"
                :value="getCurrentJob.pmSlotCount"
                :loading="getIsLoadingJobDetails"
                :disabled="isSlotSelectorDisabled"
                :key="pmTimeSlotSelectorKey"
                @change="promptIsOverAllocationDialogOrUpdatePmTimeSlots"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </transition>
    <job-allocation-slot-dialog
      v-if="jobAllocationSlotDialogPropObject.isVisible"
      :job-allocation-slot-dialog-prop-object="jobAllocationSlotDialogPropObject"
      :is-loading="getIsLoadingJobDetails"
      @confirm="jobAllocationSlotDialogPositiveAction($event.timeSlotId, $event.amSlotCount, $event.pmSlotCount)"
      @close-dialog="resetAllocationSlotDialogPropObject"
    />
  </v-layout>
</template>

<script>
import {
  dateAsDayOfWeekMonthDayYear,
  formatAsDateOnly,
  getMinimumDateOrFirstOfMonth,
  getLastDayOfMonthByYearAndMonth,
  todayAsDate,
  oneYearFromNowAsDate,
  getYearAndMonthFromDateTime
} from "@/utils/DateTimeFormatters"
import BaseToolTip from "@/components/BaseToolTip"
import JobAllocationSlotDialog from "@/components/JobAllocationSlotDialog"
import { fetchScheduleSlotDto, putJobPreferredTimeSlotDto, putJobSlotCountDto, fetchOverSlotAllocationDto, putJunkJobDto } from "@/api/dtos/JunkDtos"
import { JobTab } from "@/models/JobTab"
import { JobCreationOrigin } from "@/enums/JobCreationOrigin"
import { PreferredTimeSlot } from "@/enums/PreferredTimeSlot"
import { SlotAllocationChangeType } from "@/enums/SlotAllocationChangeType"
import { mapState, mapActions } from "pinia"
import { useMainStore } from "@/stores/Main"
import { useJobStore } from "@/stores/Job"

export default {
  name: "JobDetailsCard",
  components: { BaseToolTip, JobAllocationSlotDialog },
  data() {
    return {
      isPrimaryCardDisplayed: true,
      isMenuVisible: false,
      minimumDate: todayAsDate(),
      maximumDate: oneYearFromNowAsDate(),
      jobAllocationSlotDialogPropObject: this.getInitialJobAllocationSlotDialogPropObject(),
      preferredTimeOfDaySelectorKey: 0,
      amTimeSlotSelectorKey: 0,
      pmTimeSlotSelectorKey: 0,
      serviceDateTextFieldKey: 0,
      colorKeyItems: [
        {
          color: "error",
          description: "No Slots Are Available."
        },
        {
          color: "warning",
          description: "One Slot is Available."
        },
        {
          color: "success",
          description: "Two or More Slots Are Available."
        }
      ],
      orderKeyItems: [
        {
          color: "secondary",
          description: "First Dot Denotes Remaining First Slots."
        },
        {
          color: "secondary",
          description: "Second Dot Denotes Remaining AM Slots."
        },
        {
          color: "secondary",
          description: "Third Dot Denotes Remaining PM Slots."
        },
        {
          color: "secondary",
          description: "Fourth Dot Denotes All Remaining Slots."
        }
      ]
    }
  },
  methods: {
    ...mapActions(useMainStore, ["addJobTab"]),
    ...mapActions(useJobStore, [
      "updateScheduledDate",
      "fetchActiveFranchiseCapacitiesByFranchiseIdAndDate",
      "fetchScheduleSlotAllocations",
      "fetchOverSlotAllocation",
      "putJobAmSlotCount",
      "putJobPmSlotCount",
      "updateJunkJobPreferredTimeSlot",
      "fetchActiveJunkTrucksInOperatingUnitByDate",
      "fetchActiveFranchiseTaxes",
      "fetchJunkJobTrucks",
      "fetchJunkJobEmployees"
    ]),
    toggleCardDisplayed() {
      this.isPrimaryCardDisplayed = !this.isPrimaryCardDisplayed
    },
    async addNewJobTab() {
      let tab = new JobTab(
        this.getCurrentJob.id,
        this.getCurrentJob.jobNumber ?? "N/A",
        this.getCurrentJob.customerId,
        this.getPrimaryContactDetails.lastName,
        this.getCustomerContactsWithAddresses.businessName
      )

      await this.addJobTab(tab)
    },
    async updatePreferredTimeOfDayOrSetAllocation(timeSlotId) {
      const { slotCount, preferredTimeSlotId, preferredTimeSlotName, amSlotCount, pmSlotCount } = this.getCurrentJob
      const isSingleSlot = slotCount === 1
      const isNoPrefWithZeroSlots = slotCount === 0 && preferredTimeSlotId === PreferredTimeSlot.NONE.id
      const isChangingFromFirstToAmOrAmToFirst =
        slotCount >= 1 &&
        ((preferredTimeSlotId === PreferredTimeSlot.FIRST.id && timeSlotId === PreferredTimeSlot.AM.id) ||
          (preferredTimeSlotId === PreferredTimeSlot.AM.id && timeSlotId === PreferredTimeSlot.FIRST.id)) &&
        pmSlotCount <= 0
      const isChangingToPm =
        slotCount >= 1 && [PreferredTimeSlot.FIRST.id, PreferredTimeSlot.AM.id, PreferredTimeSlot.NONE.id].includes(preferredTimeSlotId) && timeSlotId === PreferredTimeSlot.PM.id

      if (isSingleSlot || isNoPrefWithZeroSlots || isChangingFromFirstToAmOrAmToFirst || isChangingToPm) {
        await this.promptIsOverAllocationDialogOrUpdatePreferredTimeOfDay(timeSlotId)
      } else {
        const jobAllocationSlotDialogPropObject = {
          isVisible: true,
          oldPreferredTimeOfDayId: preferredTimeSlotId,
          oldPreferredTimeOfDay: preferredTimeSlotName,
          oldNumberOfAmSlots: amSlotCount,
          oldNumberOfPmSlots: pmSlotCount,
          newPreferredTimeOfDayId: timeSlotId,
          newPreferredTimeOfDay: this.getPreferredTimeOfDayName(timeSlotId),
          numberOfSlots: slotCount
        }

        this.setAllocationSlotDialogPropObject(jobAllocationSlotDialogPropObject)
      }
    },
    async promptIsOverAllocationDialogOrUpdatePreferredTimeOfDay(timeSlotId) {
      const dto = fetchOverSlotAllocationDto(this.getCurrentJob.id, SlotAllocationChangeType.PREFERRED_TIME_OF_DAY, null, timeSlotId, null, null, null)
      const slotAllocation = await this.fetchOverSlotAllocation(dto)
      slotAllocation.isOverAllocation
        ? this.emitUpdatePreferredTimeOfDay(timeSlotId, slotAllocation)
        : await this.updatePreferredTimeOfDay(timeSlotId, true, 0, 0).catch(() => this.updatePreferredTimeOfDayKey())
    },
    emitUpdatePreferredTimeOfDay(timeSlotId, overAllocationDto) {
      this.$emit("updatePreferredTimeOfDay", {
        date: dateAsDayOfWeekMonthDayYear(this.getCurrentJobScheduledDateOrToday),
        timeSlot: this.getPreferredTimeOfDayName(timeSlotId),
        positiveAction: () =>
          this.updatePreferredTimeOfDay(timeSlotId, true, 0, 0).catch(() => {
            this.updatePreferredTimeOfDayKey()
          }),
        cancellationAction: () => this.updatePreferredTimeOfDayKey(),
        overAllocationDto: overAllocationDto
      })
    },
    getPreferredTimeOfDayName(timeSlotId) {
      return this.getTimeSlots?.slice()?.find(timeSlot => timeSlot.id === timeSlotId)?.name ?? ""
    },
    async updatePreferredTimeOfDay(timeSlotId, isAutoUpdate, amCount, pmCount) {
      const jobPreferredTimeSlotDto = putJobPreferredTimeSlotDto(this.getCurrentJob.id, timeSlotId, this.$msal.getCurrentUserId(), isAutoUpdate, amCount, pmCount)

      await this.updateJunkJobPreferredTimeSlot(jobPreferredTimeSlotDto).then(() => {
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndMonth(this.getCurrentJob.franchiseId, getYearAndMonthFromDateTime(this.getCurrentJobScheduledDateOrToday))
      })
    },
    updatePreferredTimeOfDayKey() {
      this.preferredTimeOfDaySelectorKey === 1 ? (this.preferredTimeOfDaySelectorKey = 0) : (this.preferredTimeOfDaySelectorKey = 1)
    },
    setAllocationSlotDialogPropObject(slotProps) {
      this.jobAllocationSlotDialogPropObject = { ...slotProps }
    },
    async fetchScheduleSlotAllocationsByFranchiseIdYearAndMonth(franchiseId, yearAndMonth) {
      const fromDate = getMinimumDateOrFirstOfMonth(yearAndMonth)
      const toDate = getLastDayOfMonthByYearAndMonth(yearAndMonth)
      const dto = fetchScheduleSlotDto(franchiseId, fromDate, toDate)
      return await this.fetchScheduleSlotAllocations(dto)
    },
    slotEvents(date) {
      const recordForDate = this.findScheduleSlotAllocationRecordForDate(date)
      if (recordForDate)
        return this.createSlotColors(recordForDate.firstSlotsRemaining, recordForDate.totalAmSlotsRemaining, recordForDate.totalPmSlotsRemaining, recordForDate.totalSlotsRemaining)
      return false
    },
    findScheduleSlotAllocationRecordForDate(date) {
      return this.getScheduleSlotAllocations?.find(scheduleSlotAllocation => {
        return formatAsDateOnly(scheduleSlotAllocation?.serviceDate) === formatAsDateOnly(date)
      })
    },
    createSlotColors(firstSlot, amSlot, pmSlot, totalRemainingSlots) {
      if (totalRemainingSlots <= 0) {
        return [
          this.determineEventColor(totalRemainingSlots),
          this.determineEventColor(totalRemainingSlots),
          this.determineEventColor(totalRemainingSlots),
          this.determineEventColor(totalRemainingSlots)
        ]
      }
      return [this.determineEventColor(firstSlot), this.determineEventColor(amSlot), this.determineEventColor(pmSlot), this.determineEventColor(totalRemainingSlots)]
    },
    determineEventColor(numberOfRemainingSlots) {
      if (numberOfRemainingSlots >= 2) return "success"
      else if (numberOfRemainingSlots === 1) return "warning"
      else if (numberOfRemainingSlots < 1) return "error"
    },
    async pickerUpdateHandler(yearAndMonth) {
      return await this.fetchScheduleSlotAllocationsByFranchiseIdYearAndMonth(this.getCurrentJob.franchiseId, yearAndMonth)
    },
    async promptIsOverAllocationDialogOrUpdateScheduledDate(date) {
      const dto = fetchOverSlotAllocationDto(this.getCurrentJob.id, SlotAllocationChangeType.SCHEDULED_DATE, date, null, null, null, null)
      const slotAllocation = await this.fetchOverSlotAllocation(dto)
      slotAllocation.isOverAllocation ? this.emitUpdateScheduledDate(slotAllocation, date) : await this.datePickerInputHandler(date)
    },
    emitUpdateScheduledDate(overAllocationDto, date) {
      this.$emit("updateScheduledDate", {
        date: dateAsDayOfWeekMonthDayYear(date),
        timeSlot: this.getCurrentJob.preferredTimeSlotName,
        positiveAction: () => this.datePickerInputHandler(date),
        cancellationAction: () => this.revertServiceDateSelection,
        overAllocationDto: overAllocationDto
      })
    },
    async datePickerInputHandler(date) {
      // TODO: Replace with current user's username when auth is wired up.
      const junkJobDto = putJunkJobDto(
        this.getCurrentJob.id,
        date,
        this.getCurrentJob.operatingUnitId,
        this.getCurrentJob.franchiseId,
        this.getCurrentJob.capacityId,
        this.$msal.getCurrentUserId()
      )

      await this.updateScheduledDate(junkJobDto).then(() => {
        this.setMenuVisibility(false)
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndMonth(this.getCurrentJob.franchiseId, getYearAndMonthFromDateTime(date))
        this.fetchActiveFranchiseCapacitiesByFranchiseIdAndDate({
          franchiseId: this.getCurrentJob.franchiseId,
          date: date
        })
        this.fetchActiveJunkTrucksInOperatingUnitByDate({
          date: date,
          operatingUnitId: this.getCurrentJob.operatingUnitId
        })
        this.fetchActiveFranchiseTaxes({ franchiseId: this.getCurrentJob.franchiseId, date: date })
        this.fetchJunkJobTrucks(this.getCurrentJob.id)
        this.fetchJunkJobEmployees(this.getCurrentJob.id)
      })
    },
    revertServiceDateSelection() {
      this.serviceDateTextFieldKey === 1 ? (this.serviceDateTextFieldKey = 0) : (this.serviceDateTextFieldKey = 1)
    },
    setMenuVisibility(isVisible) {
      this.isMenuVisible = isVisible
    },
    async promptIsOverAllocationDialogOrUpdateAmTimeSlots(slotCount) {
      const overSlotAllocationDto = fetchOverSlotAllocationDto(this.getCurrentJob.id, SlotAllocationChangeType.AM_SLOT, null, null, null, slotCount, null)

      try {
        const allocation = await this.fetchOverSlotAllocation(overSlotAllocationDto)
        allocation.isOverAllocation ? this.emitUpdateAmTimeSlots(allocation, slotCount) : await this.amTimeSlotInputHandler(slotCount)
      } catch {
        this.updateAmTimeSlotKey()
      }
    },
    updateAmTimeSlotKey() {
      this.amTimeSlotSelectorKey === 1 ? (this.amTimeSlotSelectorKey = 0) : (this.amTimeSlotSelectorKey = 1)
    },
    emitUpdateAmTimeSlots(overAllocationDto, slotCount) {
      this.$emit("updateAmTimeSlots", {
        date: dateAsDayOfWeekMonthDayYear(this.getCurrentJobScheduledDateOrToday),
        timeSlot: this.getPreferredTimeOfDayName(this.getCurrentJob.preferredTimeSlotId),
        positiveAction: () =>
          this.amTimeSlotInputHandler(slotCount).catch(() => {
            this.updateAmTimeSlotKey()
          }),
        cancellationAction: () => this.updateAmTimeSlotKey(),
        overAllocationDto: overAllocationDto
      })
    },
    async amTimeSlotInputHandler(slotCount) {
      const amJobSlotCountDto = putJobSlotCountDto(this.getCurrentJob.id, slotCount)
      await this.putJobAmSlotCount(amJobSlotCountDto)
    },
    async promptIsOverAllocationDialogOrUpdatePmTimeSlots(slotCount) {
      try {
        const overSlotAllocationDto = fetchOverSlotAllocationDto(this.getCurrentJob.id, SlotAllocationChangeType.PM_SLOT, null, null, null, 0, slotCount)
        const allocation = await this.fetchOverSlotAllocation(overSlotAllocationDto)
        allocation.isOverAllocation ? this.emitUpdatePmTimeSlots(allocation, slotCount) : await this.pmTimeSlotInputHandler(slotCount)
      } catch {
        this.updatePmTimeSlotKey()
      }
    },
    emitUpdatePmTimeSlots(overAllocationDto, slotCount) {
      this.$emit("updatePmTimeSlots", {
        date: dateAsDayOfWeekMonthDayYear(this.getCurrentJobScheduledDateOrToday),
        timeSlot: this.getPreferredTimeOfDayName(this.getCurrentJob.preferredTimeSlotId),
        positiveAction: () =>
          this.pmTimeSlotInputHandler(slotCount).catch(() => {
            this.updatePmTimeSlotKey()
          }),
        cancellationAction: () => this.updatePmTimeSlotKey(),
        overAllocationDto: overAllocationDto
      })
    },
    async pmTimeSlotInputHandler(slotCount) {
      const pmJobSlotCountDto = putJobSlotCountDto(this.getCurrentJob.id, slotCount)
      await this.putJobPmSlotCount(pmJobSlotCountDto)
    },
    updatePmTimeSlotKey() {
      this.pmTimeSlotSelectorKey === 1 ? (this.pmTimeSlotSelectorKey = 0) : (this.pmTimeSlotSelectorKey = 1)
    },
    async jobAllocationSlotDialogPositiveAction(timeSlotId, amSlotCount, pmSlotCount) {
      const dto = fetchOverSlotAllocationDto(this.getCurrentJob.id, SlotAllocationChangeType.SLOTS_AND_PREFERRED_TIME_OF_DAY, null, timeSlotId, null, amSlotCount, pmSlotCount)
      const slotAllocation = await this.fetchOverSlotAllocation(dto)

      try {
        slotAllocation.isOverAllocation
          ? this.emitUpdatePreferredTimeOfDayManual(timeSlotId, amSlotCount, pmSlotCount, slotAllocation)
          : await this.updatePreferredTimeOfDay(timeSlotId, false, amSlotCount, pmSlotCount).then(() => this.resetAllocationSlotDialogPropObject())
      } catch {
        this.updatePreferredTimeOfDayKey()
      }
    },
    emitUpdatePreferredTimeOfDayManual(timeSlotId, amSlotCount, pmSlotCount, overAllocationDto) {
      this.$emit("updatePreferredTimeOfDay", {
        date: dateAsDayOfWeekMonthDayYear(this.getCurrentJobScheduledDateOrToday),
        timeSlot: this.getPreferredTimeOfDayName(timeSlotId),
        positiveAction: () =>
          this.updatePreferredTimeOfDay(timeSlotId, false, amSlotCount, pmSlotCount)
            .then(() => this.resetAllocationSlotDialogPropObject())
            .catch(() => {
              this.updatePreferredTimeOfDayKey()
            }),
        cancellationAction: () => this.updatePreferredTimeOfDayKey(),
        overAllocationDto: overAllocationDto
      })
    },
    resetAllocationSlotDialogPropObject() {
      this.jobAllocationSlotDialogPropObject = this.getInitialJobAllocationSlotDialogPropObject()
      this.updatePreferredTimeOfDayKey()
    },
    getInitialJobAllocationSlotDialogPropObject() {
      return {
        isVisible: false,
        oldPreferredTimeOfDayId: null,
        oldPreferredTimeOfDay: "",
        oldNumberOfAmSlots: 0,
        oldNumberOfPmSlots: 0,
        newPreferredTimeOfDayId: null,
        newPreferredTimeOfDay: "",
        numberOfSlots: 0
      }
    }
  },
  computed: {
    ...mapState(useJobStore, [
      "getSelectedFranchise",
      "getCurrentJobScheduledDateOrToday",
      "getIsLoadingJobDetails",
      "getCurrentJob",
      "getTimeSlots",
      "getScheduleSlotAllocations",
      "getFormattedServiceDateAsDayOfWeekMonthDayYear",
      "getIsJobFieldsDisabled",
      "getIsLoadingAnyJobComponents",
      "getCurrentJobScheduledDateOrToday",
      "getPrimaryContactDetails",
      "getCustomerContactsWithAddresses",
      "getLeadSources"
    ]),
    isCustomerSelfScheduled() {
      return this.getCurrentJob.jobCreationOriginId === JobCreationOrigin.CUSTOMER.id
    },
    isMultiSlotJob() {
      return this.getCurrentJob.slotCount > 1
    },
    multiSlotToolTipTitle() {
      return `${this.getCurrentJob.slotCount} slots`
    },
    slotNumberIcon() {
      let count = this.getCurrentJob.slotCount ?? 0
      return count > 9 ? `numeric-9-plus-box-multiple-outline` : `mdi-numeric-${count}-circle`
    },
    isMultiSlotIconVisible() {
      return this.$msal.getUserHasElevatedRole()
    },
    getIsFieldsDisabled() {
      return this.getIsLoadingJobDetails || this.getIsJobFieldsDisabled || this.getIsLoadingAnyJobComponents
    },
    datePickerValue() {
      return formatAsDateOnly(this.getCurrentJobScheduledDateOrToday)
    },
    getMinimumPickerValue() {
      return this.getSelectedFranchise?.businessStartDate > todayAsDate() ? this.getSelectedFranchise.businessStartDate : todayAsDate()
    },
    getSlotCountOptions() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    isAmSlotSelectorDisabled() {
      return this.isSlotSelectorDisabled || this.getCurrentJob.preferredTimeSlotId === PreferredTimeSlot.PM.id
    },
    isSlotSelectorDisabled() {
      return this.getIsLoadingJobDetails || !this.$msal.getUserHasElevatedRole()
    },
    isJobIconSetVisible() {
      return (
        Object.keys(this.getCurrentJob ?? {}).length !== 0 &&
        Object.keys(this.getPrimaryContactDetails ?? {}).length !== 0 &&
        Object.keys(this.getCustomerContactsWithAddresses ?? {}).length !== 0
      )
    }
  }
}
</script>

<style>
.v-date-picker-table__events {
  margin-inline-start: -6px;
  margin-bottom: -1px;
}

.flip-enter-active {
  transition: all 0.7s ease;
}

.flip-leave-active {
  transition: all 0.7s ease;
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
</style>
