<template>
  <v-container class="mt-4" fluid>
    <v-row>
      <v-col>
        <settings-auth-management-users-card v-if="!isActionsDisabled" ref="settings-employee-tab-auth-management-users-card" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <settings-auth-management-roles-card v-if="!isActionsDisabled" ref="settings-employee-tab-auth-management-roles-card" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsAuthManagementUsersCard from "@/components/SettingsAuthManagementUsersCard"
import SettingsAuthManagementRolesCard from "@/components/SettingsAuthManagementRolesCard"

export default {
  name: "SettingsEmployeeTab",
  components: { SettingsAuthManagementUsersCard, SettingsAuthManagementRolesCard },
  computed: {
    isActionsDisabled() {
      return !this.$msal.getUserHasWriteSettingsPermission()
    }
  }
}
</script>
