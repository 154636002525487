<template>
  <v-container fluid class="mb-16">
    <mgt-get :resource="getUsersInOperatingUnitQuery" scopes="User.Read.All,Group.Read.All" version="v1.0" cache-enabled="true" @dataChange="onDataChanged($event)" />
    <v-row>
      <v-col class="d-flex">
        <job-queue-scheduled-date-card ref="job-queue-scheduled-date-card" />
      </v-col>
    </v-row>

    <v-row class="justify-center justify-space-around">
      <v-col class="flex-column d-flex">
        <job-queue-claimed-jobs-card ref="job-queue-claimed-jobs-card" @addresses-selected-for-navigation="addressesSelectedForNavigation" />
      </v-col>
    </v-row>
    <v-row class="mt-4 justify-center justify-space-around" justify-center>
      <v-col class="flex-column d-flex">
        <v-card elevation="4">
          <div>
            <v-row class="mb-0">
              <v-col class="mb-n2">
                <job-queue-filters-card ref="job-queue-filters-card" />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="float-end text-end me-8 mb-2">
                <div ref="job-queue-results-range" class="heading" style="opacity: 0.87">
                  <strong>{{ getQueueResultsRangeText }}</strong>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider class="mx-3 mb-1 secondaryDark" style="opacity: 20%" />
          <job-queue-slot-card ref="job-queue-slot-card" :is-loading="getIsLoadingSlotQueues" @claimJob="promptDialog" />
        </v-card>
      </v-col>
    </v-row>

    <claim-job-confirmation-dialog
      ref="job-queue-claim-job-confirmation-dialog"
      v-if="dialogOptions.isVisible"
      :is-dialog-visible="dialogOptions.isVisible"
      :title="`Claim Job ${dialogOptions.dialogTitle}?`"
      :selected-truck-id="getSelectedTruckId"
      :confirmation-action-is-loading="getIsLoadingClaimJob"
      :employees-not-on-job="dialogOptions.employeesNotOnJob"
      :employees-on-job="dialogOptions.employeesOnJob"
      :trucks-on-job="dialogOptions.trucksOnJob"
      :trucks-not-on-job="dialogOptions.trucksNotOnJob"
      @confirm="claimJob($event.employeeIds, $event.truckIds, $event.drivers)"
      @closeDialog="resetDialogOptions"
    />

    <job-queue-address-picker-dialog
      ref="job-queue-address-picker"
      :is-dialog-visible="isJobQueueAddressPickerDialogVisible"
      :addresses="getAddressesForNavigation"
      @closeDialog="closeAddressPickerDialog"
    />
  </v-container>
</template>

<script>
import JobQueueScheduledDateCard from "@/components/JobQueueScheduledDateCard"
import JobQueueClaimedJobsCard from "@/components/JobQueueClaimedJobsCard"
import JobQueueFiltersCard from "@/components/JobQueueFiltersCard"
import JobQueueSlotCard from "@/components/JobQueueSlotCard"
import ClaimJobConfirmationDialog from "@/components/ClaimJobConfirmationDialog"
import JobQueueAddressPickerDialog from "@/components/JobQueueAddressPickerDialog"
import { EnvironmentVariant } from "@/enums/Environments"
import { useMainStore } from "@/stores/Main"
import { useJobQueueStore } from "@/stores/JobQueue"
import { useSnackbarStore } from "@/stores/Snackbar"
import { mapActions, mapState } from "pinia"
import { claimJobDto } from "@/api/dtos/JunkDtos"
import { formatTimeAsDateUtc, todayAsDate } from "@/utils/DateTimeFormatters"
import { persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"
import { get } from "@/store/indexeddb/KeyVal"

export default {
  name: "JobQueue",
  components: { JobQueueScheduledDateCard, JobQueueClaimedJobsCard, JobQueueFiltersCard, JobQueueSlotCard, ClaimJobConfirmationDialog, JobQueueAddressPickerDialog },
  data() {
    return {
      dialogOptions: {
        isVisible: false,
        dialogTitle: "",
        employeesOnJob: [],
        employeesNotOnJob: [],
        trucksOnJob: [],
        trucksNotOnJob: [],
        jobId: undefined,
        scheduledDate: undefined
      },
      isJobQueueAddressPickerDialogVisible: false
    }
  },
  computed: {
    ...mapState(useMainStore, ["getSelectedOperatingUnitId", "getAppEnvironment"]),
    ...mapState(useJobQueueStore, [
      "getScheduledDate",
      "getIsLoadingClaimJob",
      "getIsLoadingSlotQueues",
      "getActiveTrucks",
      "getActiveEmployees",
      "getSelectedTruckId",
      "getAddressesForNavigation",
      "getUserCurrentPosition",
      "getFilteredJobQueue",
      "getJobQueue"
    ]),
    getQueueResultsRangeText() {
      return `Displaying ${this.getFilteredJobQueue?.length} of ${this.getJobQueue?.length}`
    },
    getUsersInOperatingUnitQuery() {
      return `/groups/${this.getSelectedOperatingUnitId}/members/microsoft.graph.user?$expand=memberOf&$count=true`
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["addSnackbar"]),
    ...mapActions(useJobQueueStore, [
      "fetchAllQueuedJobsByScheduledDateAndOperatingUnitId",
      "fetchActiveJunkTrucksInOperatingUnitByDate",
      "claimJunkJobByJobId",
      "resetAttributes",
      "fetchClaimedJobDistancesFromUser",
      "setUsersInOperatingUnit",
      "setSelectedStatuses",
      "setDistanceFromMeFilter",
      "setPreferredTimeSlotFilter",
      "setSelectedFranchisesFilter",
      "fetchFranchisesInOperatingUnit",
      "setUserCurrentPosition",
      "setSelectedTruckCapacity"
    ]),
    onDataChanged(event) {
      let data = event?.detail?.response?.value ?? []

      let rolesToRemove = ["corporate admin", "sales support"]
      if (this.getAppEnvironment !== EnvironmentVariant.PRODUCTION) {
        rolesToRemove = rolesToRemove.map(role => `${role} (${this.getAppEnvironment})`)
      }
      data = data.filter(item => !item.memberOf.some(group => rolesToRemove.some(role => group?.displayName?.toLowerCase() === role)))

      this.setUsersInOperatingUnit(data)
    },
    resetDialogOptions() {
      this.dialogOptions = {
        isVisible: false,
        dialogTitle: "",
        employeesOnJob: [],
        employeesNotOnJob: [],
        trucksOnJob: [],
        trucksNotOnJob: [],
        jobId: undefined,
        scheduledDate: undefined
      }
    },
    getUserLatitudeAndLongitude() {
      return new Promise((resolve, reject) => {
        const success = position => {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          const userCurrentPosition = { lat: latitude, lng: longitude }
          this.setUserCurrentPosition(userCurrentPosition)
          resolve(userCurrentPosition)
        }

        const error = err => {
          reject(err)
          this.addSnackbar(persistentErrorSnackbarWithPositiveAction("Unable to get user location, please try again.", "Retry", () => this.getUserLatitudeAndLongitude()))
        }

        navigator.geolocation.getCurrentPosition(success, error)
      })
    },
    promptDialog(job) {
      const availableEmployees = this.getEmployeesNotOnJob(job.jobEmployees)
      const availableTrucks = this.getTrucksNotOnJob(job.jobTrucks)

      this.dialogOptions = {
        isVisible: true,
        dialogTitle: job.jobNumber,
        jobId: job.jobId,
        scheduledDate: job.scheduledDate,
        employeesOnJob: job.jobEmployees,
        employeesNotOnJob: availableEmployees,
        trucksOnJob: job.jobTrucks,
        trucksNotOnJob: availableTrucks
      }
    },
    getEmployeesNotOnJob(jobEmployees) {
      return this.getActiveEmployees?.slice().filter(employee => !jobEmployees?.some(jobEmployee => employee.id === jobEmployee.employeeId))
    },
    getTrucksNotOnJob(jobTrucks) {
      return this.getActiveTrucks?.slice().filter(truck => !jobTrucks?.some(jobTruck => truck.junkTruckId === jobTruck.junkTruckId))
    },
    async claimJob(employeeIds, truckIds, drivers) {
      const defaultStartAndEndDateTime = formatTimeAsDateUtc(this.dialogOptions.scheduledDate, "08:00")
      const dto = claimJobDto(this.$msal.getCurrentUserId(), this.dialogOptions.jobId, employeeIds, truckIds, drivers, defaultStartAndEndDateTime)
      await this.claimJunkJobByJobId({ jobId: this.dialogOptions.jobId, claimJobDto: dto }).then(async () => {
        await this.fetchAllQueuedJobsAndFetchDistancesFromUser(this.getScheduledDate, this.getSelectedOperatingUnitId)
        this.resetDialogOptions()
      })
    },
    async fetchAllQueuedJobsAndFetchDistancesFromUser(date, operatingUnitId) {
      await this.fetchAllQueuedJobsByScheduledDateAndOperatingUnitId({ date: date, operatingUnitId: operatingUnitId }).then(async () => {
        await this.fetchClaimedJobDistancesFromUser(this.getUserCurrentPosition)
      })
    },
    addressesSelectedForNavigation() {
      this.isJobQueueAddressPickerDialogVisible = true
    },
    closeAddressPickerDialog() {
      this.isJobQueueAddressPickerDialogVisible = false
    }
  },
  async created() {
    await this.getUserLatitudeAndLongitude()

    if (this.getSelectedOperatingUnitId !== null && this.getSelectedOperatingUnitId !== undefined) {
      this.fetchActiveJunkTrucksInOperatingUnitByDate({ date: todayAsDate(), operatingUnitId: this.getSelectedOperatingUnitId })
      await this.fetchAllQueuedJobsAndFetchDistancesFromUser(this.getScheduledDate, this.getSelectedOperatingUnitId)
      this.fetchFranchisesInOperatingUnit(this.getSelectedOperatingUnitId)
    }

    this.$eventBus.$on("operating-unit-updated", async operatingUnitMetadataObj => {
      this.resetAttributes()
      this.fetchActiveJunkTrucksInOperatingUnitByDate({
        date: todayAsDate(),
        operatingUnitId: operatingUnitMetadataObj.id
      })
      this.fetchFranchisesInOperatingUnit(operatingUnitMetadataObj.id)
      await this.fetchAllQueuedJobsAndFetchDistancesFromUser(this.getScheduledDate, operatingUnitMetadataObj.id)
    })

    this.$eventBus.$on("update-distance-from-me", async () => {
      await this.getUserLatitudeAndLongitude()
      await this.fetchClaimedJobDistancesFromUser(this.getUserCurrentPosition)
    })

    await get("jobQueuePreferredTimeSlotsFilter").then(async result => {
      await this.setPreferredTimeSlotFilter(result)
    })

    await get("jobQueueDistanceFromMeFilter").then(async result => {
      await this.setDistanceFromMeFilter(result)
    })

    await get("jobQueueStatusFilters").then(async result => {
      await this.setSelectedStatuses(result)
    })

    await get("jobQueueFranchisesFilter").then(async result => {
      await this.setSelectedFranchisesFilter(result)
    })

    await get("jobQueueTruckCapacityFilters").then(async result => {
      await this.setSelectedTruckCapacity(result)
    })
  },
  destroyed() {
    this.$eventBus.$off("update-distance-from-me")
  }
}
</script>
