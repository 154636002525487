import { defineStore } from "pinia"
import { formatAsDateOnly, isDateOnOrAfterToday, todayAsDate } from "@/utils/DateTimeFormatters"
import junkApi from "@/api/Junk"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { errorSnackbar, errorSnackbarWithPositiveAction, persistentErrorSnackbarWithPositiveAction, successSnackbar } from "@/utils/SnackbarBuilder"
import * as Sentry from "@sentry/vue"
import { useSnackbarStore } from "@/stores/Snackbar"
import { useMainStore } from "@/stores/Main"

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    activeFranchiseCapacities: [],
    isLoadingJunkTrucks: false,
    isLoadingTruckUnavailability: false,
    isLoadingTruckUtilizationRates: false,
    isLoadingFranchiseSpecialHours: false,
    inactiveFranchiseCapacities: [],
    junkTrucks: [],
    junkTruckWeeklyAllocations: [],
    selectedFranchise: undefined,
    unavailableTrucks: [],
    vehicleFuelTypes: [],
    franchisesInOperatingUnit: [],
    usersInOperatingUnit: [],
    roles: [],
    selectedUser: {},
    franchiseTaxes: [],
    isLoadingFranchiseBusinessHours: false,
    franchiseSpecialHours: [],
    isLoadingTipConfiguration: false,
    tipConfiguration: {},
    isLoadingRolesForUser: false,
    isLoadingUsers: false,
    isLoadingRoles: false,
    isLoadingFranchiseTax: false,
    isLoadingFranchiseAddOns: false,
    isLoadingFranchiseDiscounts: false,
    franchiseBusinessHours: [],
    selectedFranchiseObject: {},
    isLoadingFranchiseDistanceCutoff: false,
    isLoadingOperatingUnitAllocationGroups: false,
    operatingUnitAllocationGroups: [],
    nationalDiscounts: [],
    operatingUnitDiscounts: [],
    franchiseElectedDiscountsForWebsite: [],
    isLoadingDiscounts: false,
    isLoadingFranchiseElectedDiscountsForWebsite: false,
    isLoadingFranchisesInOperatingUnit: false
  }),
  getters: {
    /* franchise */
    getIsLoadingFranchisesInOperatingUnit() {
      return this.isLoadingFranchisesInOperatingUnit
    },
    getFranchisesInOperatingUnit() {
      return this.franchisesInOperatingUnit?.slice()
    },
    getSelectedFranchiseObject() {
      return this.selectedFranchiseObject
    },
    getSelectedFranchise() {
      return this.selectedFranchise
    },
    /* Cutoff */
    getIsLoadingFranchiseDistanceCutoff() {
      return this.isLoadingFranchiseDistanceCutoff
    },
    /* taxes */
    getFranchiseTaxes() {
      return this.franchiseTaxes?.slice()?.sort((a, b) => a.taxName.localeCompare(b.taxName)) ?? []
    },
    getIsLoadingFranchiseTax() {
      return this.isLoadingFranchiseTax
    },
    /* capacities */
    getAllInactiveFranchiseCapacities() {
      return this.inactiveFranchiseCapacities?.slice()
    },
    getAllActiveFranchiseCapacities() {
      return this.activeFranchiseCapacities?.slice()
    },
    /* trucks */
    getVehicleFuelTypes() {
      return this.vehicleFuelTypes?.slice()
    },
    getActiveAvailableTrucksInOperatingUnit() {
      return this.getActiveTrucksInOperatingUnitSortedByName
        ?.slice()
        .filter(activeOrFutureTruck => !this.unavailableTrucks.some(unavailableTruck => activeOrFutureTruck.id === unavailableTruck.junkTruckId))
    },
    getActiveTrucksInOperatingUnitSortedByName() {
      return this.junkTrucks
        .slice()
        .filter(jt =>
          formatAsDateOnly(jt.beginDateTime) <= todayAsDate() &&
          (formatAsDateOnly(jt.endDateTime) ?? todayAsDate()) >= todayAsDate())
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    getActiveAndFutureTrucksInOperatingUnitSortedByName() {
      return this.junkTrucks
        .slice()
        .filter(jt => (formatAsDateOnly(jt.endDateTime) ?? todayAsDate()) >= todayAsDate())
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    getAllTrucksInOperatingUnitSortedByName() {
      return this.junkTrucks.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    getPastTrucksInOperatingUnit() {
      return this.junkTrucks.slice().filter(jt =>
        formatAsDateOnly(jt.beginDateTime) < todayAsDate() &&
        (formatAsDateOnly(jt.endDateTime) ?? todayAsDate()) < todayAsDate()
      )
    },
    getPastUnavailableTrucks() {
      return this.unavailableTrucks.slice().filter(ut =>
        formatAsDateOnly(ut.beginDateTime) < todayAsDate() &&
        (formatAsDateOnly(ut.endDateTime) ?? todayAsDate()) < todayAsDate()
      )
    },
    getActiveAndFutureUnavailableTrucks() {
      return this.unavailableTrucks.slice().filter(jt => (formatAsDateOnly(jt.endDateTime) ?? todayAsDate()) >= todayAsDate())
    },
    getAllUnavailableTrucks() {
      return this.unavailableTrucks?.slice()
    },
    getJunkTrucksLoadingState() {
      return this.isLoadingJunkTrucks
    },
    getTruckUnavailabilityLoadingState() {
      return this.isLoadingTruckUnavailability
    },
    getTruckUtilizationRatesLoadingState() {
      return this.isLoadingTruckUtilizationRates
    },
    /* users/ roles */
    getUsersInOperatingUnit() {
      return this.usersInOperatingUnit?.slice()
    },
    getRoles() {
      return this.roles.slice()
    },
    getSelectedUser() {
      return this.selectedUser
    },
    getIsLoadingUsers() {
      return this.isLoadingUsers
    },
    getIsLoadingRoles() {
      return this.isLoadingRoles
    },
    getIsLoadingRolesForUser() {
      return this.isLoadingRolesForUser
    },
    /* allocation */
    getWeeklyAllocations() {
      return this.junkTruckWeeklyAllocations.slice()
    },
    getIsLoadingOperatingUnitAllocationGroups() {
      return this.isLoadingOperatingUnitAllocationGroups
    },
    getOperatingUnitAllocationGroups() {
      return this.operatingUnitAllocationGroups
    },
    /* business hours */
    getIsLoadingFranchiseBusinessHours() {
      return this.isLoadingFranchiseBusinessHours
    },
    getFranchiseBusinessHours() {
      return this.franchiseBusinessHours.slice()
    },
    /* special business hours */
    getIsLoadingFranchiseSpecialHours() {
      return this.isLoadingFranchiseSpecialHours
    },
    getFranchiseSpecialHours() {
      return this.franchiseSpecialHours.slice()
    },
    /* tip configuration */
    getIsLoadingTipConfiguration() {
      return this.isLoadingTipConfiguration
    },
    getTipConfiguration() {
      return this.tipConfiguration
    },
    /* discounts */
    getNationalDiscounts() {
      return this.nationalDiscounts
    },
    getActiveAndFutureNationalDiscounts() {
      return this.nationalDiscounts.map(item => {
        const activeFranchiseElectedDiscounts = item.franchiseElectedDiscounts.filter(discount =>
          isDateOnOrAfterToday(discount.endDate)
        )
        return { ...item, franchiseElectedDiscounts: activeFranchiseElectedDiscounts }
      })
    },
    getOperatingUnitDiscounts() {
      return this.operatingUnitDiscounts
    },
    getActiveAndFutureOperatingUnitDiscounts() {
      return this.operatingUnitDiscounts.map(item => {
        const activeFranchiseElectedDiscounts = item.franchiseElectedDiscounts.filter(discount =>
          isDateOnOrAfterToday(discount.endDate)
        )
        return { ...item, franchiseElectedDiscounts: activeFranchiseElectedDiscounts }
      })
    },
    getFranchiseElectedDiscountsForWebsite() {
      return this.franchiseElectedDiscountsForWebsite
    },
    getIsLoadingDiscounts() {
      return this.isLoadingDiscounts
    },
    getIsLoadingFranchiseElectedDiscountsForWebsite() {
      return this.isLoadingFranchiseElectedDiscountsForWebsite
    }
  },
  actions: {
    /* Cross-Cutting */
    resetFranchiseData() {
      this.setSelectedFranchise(undefined)
      this.setSelectedFranchiseObject({})
      this.setInactiveFranchiseCapacities([])
      this.setActiveFranchiseCapacities([])
      this.setFranchiseTaxes([])
      this.setFranchiseBusinessHours([])
      this.setFranchiseSpecialHours([])
      this.setFranchiseElectedDiscountsForWebsite([])
      this.setTipConfiguration({})
    },
    // setSelectedUser(user) { // todo: unused, part of original impl for managing Employees - SettingsAuthManagementAddUserRolesDialog SettingsAuthManagementDeleteUserRolesDialog
    //   this.selectedUser = Object.assign({}, user)
    // },
    // async resetSelectedUser() { // todo: unused, part of original impl for managing Employees - SettingsAuthManagementAddUserRolesDialog, SettingsAuthManagementDeleteUserRolesDialog
    //   this.setSelectedUser({})
    // },

    /* Franchise */
    setIsLoadingFranchisesInOperatingUnit(isLoading) {
      this.isLoadingFranchisesInOperatingUnit = isLoading
    },
    setSelectedFranchise(franchiseId) {
      this.selectedFranchise = franchiseId
    },
    setSelectedFranchiseObject(franchise) {
      this.selectedFranchiseObject = Object.assign({}, franchise)
    },
    setFranchisesInOperatingUnit(franchises) {
      this.franchisesInOperatingUnit = franchises
    },
    async fetchActiveFranchisesInOperatingUnit(operatingUnitId) {
      this.setIsLoadingFranchisesInOperatingUnit(true)
      return await junkApi
        .fetchActiveFranchisesInOperatingUnit(operatingUnitId)
        .then(franchises => {
          this.setFranchisesInOperatingUnit(franchises)

          if (franchises.length === 1) {
            const franchiseId = franchises[0].franchiseId
            this.setSelectedFranchise(franchiseId)
            this.setSelectedFranchiseObject(franchises[0])

            this.fetchFranchiseCapacitiesByFranchiseId(franchiseId)
            this.fetchFranchiseBusinessHoursByFranchiseId(franchiseId)
            this.fetchFranchiseSpecialHoursByFranchiseId(franchiseId)
            this.fetchElectedDiscountsForWebsiteByFranchiseId(franchiseId)
            this.fetchTipConfigurationByFranchiseId(franchiseId)
          }
          return Promise.resolve(franchises)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchises in Operating Unit! ${errorMessageHandler(error)}`, "Retry", () => this.fetchActiveFranchisesInOperatingUnit(operatingUnitId))
          )
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingFranchisesInOperatingUnit(false))
    },

    /* Capacities */
    setActiveFranchiseCapacities(activeFranchiseCapacities) {
      this.activeFranchiseCapacities = activeFranchiseCapacities
    },
    setInactiveFranchiseCapacities(inActiveFranchiseCapacities) {
      this.inactiveFranchiseCapacities = inActiveFranchiseCapacities
    },
    setIsLoadingTruckUtilizationRates(isLoading) {
      this.isLoadingTruckUtilizationRates = isLoading
    },
    async fetchFranchiseCapacitiesByFranchiseId(franchiseId) {
      this.setIsLoadingTruckUtilizationRates(true)
      this.setInactiveFranchiseCapacities([])
      this.setActiveFranchiseCapacities([])

      return await junkApi
        .fetchFranchiseCapacitiesByFranchiseId(franchiseId)
        .then(data => {
          this.setInactiveFranchiseCapacities(data.inactiveFranchiseCapacityDtos)
          this.setActiveFranchiseCapacities(data.activeFranchiseCapacityDtos)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            errorSnackbarWithPositiveAction(`Error Fetching Capacities! ${errorMessageHandler(error)}`, "Retry", () => this.fetchFranchiseCapacitiesByFranchiseId(franchiseId))
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTruckUtilizationRates(false)
        })
    },
    async upsertFranchiseCapacity(franchiseCapacity) {
      this.setIsLoadingTruckUtilizationRates(true)
      return await junkApi
        .upsertFranchiseCapacity(franchiseCapacity)
        .then(data => {
          this.setInactiveFranchiseCapacities(data.inactiveFranchiseCapacityDtos)
          this.setActiveFranchiseCapacities(data.activeFranchiseCapacityDtos)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error updating franchise capacity! ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTruckUtilizationRates(false)
        })
    },

    /* Business Hours */
    setIsLoadingFranchiseBusinessHours(isLoading) {
      this.isLoadingFranchiseBusinessHours = isLoading
    },
    setFranchiseBusinessHours(franchiseBusinessHours) {
      this.franchiseBusinessHours = franchiseBusinessHours
    },
    async fetchFranchiseBusinessHoursByFranchiseId(franchiseId) {
      this.setIsLoadingFranchiseBusinessHours(true)
      this.setFranchiseBusinessHours([])
      return await junkApi
        .fetchFranchiseBusinessHours(franchiseId)
        .then(data => {
          this.setFranchiseBusinessHours(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            errorSnackbarWithPositiveAction(`Error: ${errorMessageHandler(error)}`, "Retry", () => this.fetchFranchiseBusinessHoursByFranchiseId(franchiseId))
          )
          return Promise.reject(error.response.data)
        })
        .finally(() => {
          this.setIsLoadingFranchiseBusinessHours(false)
        })
    },
    replaceFranchiseBusinessHour(franchiseBusinessHour) {
      const idx = this.franchiseBusinessHours.findIndex(c => c.franchiseBusinessHourId === franchiseBusinessHour.franchiseBusinessHourId)
      if (~idx) {
        this.franchiseBusinessHours.splice(idx, 1, franchiseBusinessHour)
      }
    },
    async updateFranchiseBusinessHours(putFranchiseBusinessHourDto){
      this.setIsLoadingFranchiseBusinessHours(true)
      return await junkApi
        .updateFranchiseBusinessHour(putFranchiseBusinessHourDto)
        .then(data => {
          this.replaceFranchiseBusinessHour(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Business Hours! ${errorMessageHandler(error)}`))
          return Promise.reject(error.response.data)
        })
        .finally(() => {
          this.setIsLoadingFranchiseBusinessHours(false)
        })
    },

    /* Special Business Hours */
    setIsLoadingFranchiseSpecialHours(isLoading) {
      this.isLoadingFranchiseSpecialHours = isLoading
    },
    addFranchiseSpecialHour(specialHour) {
      this.franchiseSpecialHours.push(specialHour)
    },
    setFranchiseSpecialHours(specialHours) {
      this.franchiseSpecialHours = specialHours
    },
    async fetchFranchiseSpecialHoursByFranchiseId(franchiseId) {
      this.setIsLoadingFranchiseSpecialHours(true)
      return await junkApi
        .fetchFranchiseSpecialHoursByFranchiseId(franchiseId)
        .then(data => {
          this.setFranchiseSpecialHours(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.fetchFranchiseSpecialHoursByFranchiseId(franchiseId)))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseSpecialHours(false)
        })
    },
    async createFranchiseSpecialHour(franchiseSpecialHourDto) {
      this.setIsLoadingFranchiseSpecialHours(true)
      return await junkApi
        .createFranchiseSpecialHour(franchiseSpecialHourDto)
        .then(data => {
          this.addFranchiseSpecialHour(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Creating Franchise Special Hour! ${errorMessageHandler(error)}`))
          return Promise.reject(error.response.data)
        })
        .finally(() => {
          this.setIsLoadingFranchiseSpecialHours(false)
        })
    },
    replaceFranchiseSpecialHour(franchiseBusinessHour) {
      const idx = this.franchiseSpecialHours.findIndex(c => c.franchiseSpecialHourId === franchiseBusinessHour.franchiseSpecialHourId)
      if (~idx) {
        this.franchiseSpecialHours.splice(idx, 1, franchiseBusinessHour)
      }
    },
    async updateFranchiseSpecialHour(putFranchiseSpecialHourDto){
      this.setIsLoadingFranchiseSpecialHours(true)
      return await junkApi
        .updateFranchiseSpecialHour(putFranchiseSpecialHourDto)
        .then(data => {
          this.replaceFranchiseSpecialHour(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Special Hours! ${errorMessageHandler(error)}`))
          return Promise.reject(error.response.data)
        })
        .finally(() => {
          this.setIsLoadingFranchiseSpecialHours(false)
        })
    },
    removeFranchiseSpecialHour(id) {
      let index = this.franchiseSpecialHours.findIndex(c => c.franchiseSpecialHourId === id)
      if (~index) {
        this.franchiseSpecialHours.splice(index, 1)
      }
    },
    async deleteFranchiseSpecialHour(franchiseSpecialHourId){
      this.setIsLoadingFranchiseSpecialHours(true)
      return await junkApi
        .deleteFranchiseSpecialHour(franchiseSpecialHourId)
        .then(data => {
          this.removeFranchiseSpecialHour(franchiseSpecialHourId)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Deleting Special Hours! ${errorMessageHandler(error)}`))
          return Promise.reject(error.response.data)
        })
        .finally(() => {
          this.setIsLoadingFranchiseSpecialHours(false)
        })
    },

    /* Discounts */
    setIsLoadingDiscounts(isLoading) {
      this.isLoadingDiscounts = isLoading
    },

    /* National Discounts */
    setNationalDiscounts(discounts) {
      this.nationalDiscounts.splice(0, this.nationalDiscounts?.length ?? 0, ...discounts)
    },
    async fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId(operatingUnitId) {
      this.setIsLoadingDiscounts(true)
      this.setNationalDiscounts([])

      await junkApi
        .fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId(operatingUnitId)
        .then(response => {
          this.setNationalDiscounts(response)
          return Promise.resolve(response)
        })
        .catch(async error => {
          const snackbarStore = useSnackbarStore()
          await snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },
    updateNationalDiscount(discount) {
      let index = this.nationalDiscounts.findIndex(d => d.nationalDiscountId === discount.nationalDiscountId)
      if (~index) {
        this.nationalDiscounts.splice(index, 1, discount)
      }
    },
    async associateFranchisesToNationalDiscount(dto) {
      await this.setIsLoadingDiscounts(true)

      await junkApi
        .addFranchisesToNationalDiscount(dto)
        .then(response => {
          this.updateNationalDiscount(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },

    /* Operating Unit Discounts */
    addOperatingUnitDiscount(discount) {
      this.operatingUnitDiscounts.push(discount)
    },
    async createOperatingUnitDiscount(createDiscountsDto) {
      this.setIsLoadingDiscounts(true)

      await junkApi
        .createOperatingUnitDiscount(createDiscountsDto)
        .then(response => {
          this.addOperatingUnitDiscount(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },
    setOperatingUnitDiscounts(discounts) {
      this.operatingUnitDiscounts.splice(0, this.operatingUnitDiscounts?.length ?? 0, ...discounts)
    },
    async fetchOperatingUnitDiscounts(operatingUnitId) {
      this.setIsLoadingDiscounts(true)
      this.setOperatingUnitDiscounts([])

      await junkApi
        .fetchOperatingUnitDiscounts(operatingUnitId)
        .then(response => {
          this.setOperatingUnitDiscounts(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },
    async updateOperatingUnitDiscountByDiscount(discount) { // todo: maybe come up with a better name?
      let index = this.operatingUnitDiscounts.findIndex(d => d.operatingUnitDiscountId === discount.operatingUnitDiscountId)
      if (~index) {
        this.operatingUnitDiscounts.splice(index, 1, discount)
      }
    },
    async associateFranchisesToOperatingUnitDiscount(dto) {
      this.setIsLoadingDiscounts(true)
      await junkApi
        .addFranchisesToOperatingUnitDiscount(dto)
        .then(response => {
          this.updateOperatingUnitDiscountByDiscount(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          Sentry.captureException(error)
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },
    async updateOperatingUnitDiscount(dto) {
      this.setIsLoadingDiscounts(true)
      await junkApi
        .updateOperatingUnitDiscount(dto)
        .then(response => {
          this.updateOperatingUnitDiscountByDiscount(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },

    /* Franchise-Elected Discounts */
    async updateFranchiseElectedOperatingUnitDiscount(franchiseElectedDiscount) {
      let index = this.operatingUnitDiscounts.findIndex(d => d.operatingUnitDiscountId === franchiseElectedDiscount.discountId)
      if (~index) {
        let franchiseIndex = this.operatingUnitDiscounts[index].franchiseElectedDiscounts.findIndex(
          f => f.franchiseElectedDiscountId === franchiseElectedDiscount.franchiseElectedDiscountId
        )
        if (~franchiseIndex) {
          this.operatingUnitDiscounts[index].franchiseElectedDiscounts.splice(franchiseIndex, 1, franchiseElectedDiscount)
        }
      }
    },
    async updateOperatingUnitFranchiseElectedDiscount(dto) {
      this.setIsLoadingDiscounts(true)
      await junkApi
        .updateOperatingUnitFranchiseElectedDiscount(dto)
        .then(response => {
          this.updateFranchiseElectedOperatingUnitDiscount(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },
    deleteFranchiseElectedOperatingUnitDiscount(discount) {
      let index = this.operatingUnitDiscounts.findIndex(d => d.operatingUnitDiscountId === discount.discountId)
      if (~index) {
        let franchiseElectedDiscountIndex = this.operatingUnitDiscounts[index].franchiseElectedDiscounts.findIndex(
          f => f.franchiseElectedDiscountId === discount.franchiseElectedDiscountId
        )
        if (~franchiseElectedDiscountIndex) {
          this.operatingUnitDiscounts[index].franchiseElectedDiscounts.splice(franchiseElectedDiscountIndex, 1)
        }
      }
    },
    async deleteOperatingUnitFranchiseElectedDiscount(discountId) {
      this.setIsLoadingDiscounts(true)
      await junkApi
        .deleteOperatingUnitFranchiseElectedDiscountByDiscountId(discountId)
        .then(response => {
          this.deleteFranchiseElectedOperatingUnitDiscount(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },

    /* Elected Discounts for Website */
    setIsLoadingFranchiseElectedDiscountsForWebsite(isLoading) {
      this.isLoadingFranchiseElectedDiscountsForWebsite = isLoading
    },
    setFranchiseElectedDiscountsForWebsite(discounts) {
      this.franchiseElectedDiscountsForWebsite.splice(0, this.franchiseElectedDiscountsForWebsite?.length ?? 0, ...discounts)
    },
    async fetchElectedDiscountsForWebsiteByFranchiseId(franchiseId) { // ForWebsite
      this.setIsLoadingFranchiseElectedDiscountsForWebsite(true)
      this.setFranchiseElectedDiscountsForWebsite([])

      await junkApi
        .fetchFranchiseElectedDiscountsForWebsiteByFranchiseId(franchiseId) // replace with fetch all
        .then(franchiseElectedNationalDiscounts => {
          this.setFranchiseElectedDiscountsForWebsite(franchiseElectedNationalDiscounts)
          return Promise.resolve(franchiseElectedNationalDiscounts)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingFranchiseElectedDiscountsForWebsite(false))
    },
    addFranchiseElectedDiscountForWebsite(discount) {
      const index = this.franchiseElectedDiscountsForWebsite.findIndex(d => d.franchiseElectedDiscountId === discount.franchiseElectedDiscountId)
      if (~index) {
        this.franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.push(discount)
      }
    },
    async createElectedDiscountForWebsite(createDiscountsDto) {
      this.setIsLoadingDiscounts(true)
      await junkApi
        .createFranchiseElectedDiscountForWebsite(createDiscountsDto)
        .then(response => {
          this.addFranchiseElectedDiscountForWebsite(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },
    updateFranchiseElectedDiscountForWebsite(franchiseElectedDiscountForWebsite) {
      let index = this.franchiseElectedDiscountsForWebsite.findIndex(d => d.franchiseElectedDiscountId === franchiseElectedDiscountForWebsite.franchiseElectedDiscountId)
      if (~index) {
        let electedDiscountForWebsiteIndex = this.franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.findIndex(
          f => f.franchiseElectedDiscountForWebsiteId === franchiseElectedDiscountForWebsite.franchiseElectedDiscountForWebsiteId
        )
        if (~index) { // todo: Determine if should this be off of electedDiscountForWebsiteIndex?
          this.franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.splice(electedDiscountForWebsiteIndex, 1, franchiseElectedDiscountForWebsite)
        }
      }
    },
    async updateElectedDiscountForWebsite(putDiscountsDto) {
      this.setIsLoadingDiscounts(true)
      await junkApi
        .updateFranchiseElectedDiscountForWebsite(putDiscountsDto)
        .then(response => {
          this.updateFranchiseElectedDiscountForWebsite(response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },
    removeFranchiseElectedDiscountForWebsite(discount) {
      let index = this.franchiseElectedDiscountsForWebsite.findIndex(d => d.franchiseElectedDiscountId === discount.franchiseElectedDiscountId)
      if (~index) {
        let electedDiscountForWebsiteIndex = this.franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.findIndex(
          f => f.franchiseElectedDiscountForWebsiteId === discount.franchiseElectedDiscountForWebsiteId
        )
        if (~electedDiscountForWebsiteIndex) {
          this.franchiseElectedDiscountsForWebsite[index].franchiseElectedDiscountsForWebsite.splice(electedDiscountForWebsiteIndex, 1)
        }
      }
    },
    async deleteElectedDiscountForWebsite(discount) {
      this.setIsLoadingDiscounts(true)
      await junkApi
        .deleteFranchiseElectedDiscountForWebsiteById(discount.franchiseElectedDiscountForWebsiteId)
        .then(response => {
          this.removeFranchiseElectedDiscountForWebsite(discount)
          return Promise.resolve(response)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(errorMessageHandler(error)))
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingDiscounts(false))
    },

    /* Fuel Types */
    setVehicleFuelTypes(vehicleFuelTypes) {
      this.vehicleFuelTypes = vehicleFuelTypes
    },
    async fetchVehicleFuelTypes() {
      useMainStore().setIsLoading(true)
      return await junkApi
        .fetchVehicleFuelTypes()
        .then(data => {
          this.setVehicleFuelTypes(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(persistentErrorSnackbarWithPositiveAction(`Error Fetching Fuel Types! ${errorMessageHandler(error)}`, "Retry", () => this.fetchVehicleFuelTypes()))
          return Promise.reject(error)
        })
        .finally(() => useMainStore().setIsLoading(false))
    },

    /* Trucks */
    setIsLoadingJunkTrucks(isLoading) {
      this.isLoadingJunkTrucks = isLoading
    },
    addJunkTruck(junkTruck) {
      this.junkTrucks = this.junkTrucks.filter(jt => {
        return jt.id !== junkTruck.id
      })
      this.junkTrucks.push(junkTruck)
    },
    addNewJunkTruckWeeklyAllocations(data) {
      data.forEach(weeklyAllocation => {
        this.junkTruckWeeklyAllocations.push(weeklyAllocation)
      })
    },
    async createJunkTruck(junkTruckDto) {
      this.setIsLoadingJunkTrucks(true)
      return await junkApi
        .createJunkTruck(junkTruckDto.operatingUnitId, junkTruckDto)
        .then(data => {
          this.addJunkTruck(data)
          this.addNewJunkTruckWeeklyAllocations(data.junkTruckWeeklyAllocationDtos)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Creating Truck! ${errorMessageHandler(error)}`))
          return Promise.reject(error.response.data)
        })
        .finally(() => {
          this.setIsLoadingJunkTrucks(false)
        })
    },
    async updateJunkTruck(putJunkTruckDto) {
      this.setIsLoadingJunkTrucks(true)
      return await junkApi
        .updateJunkTruck(putJunkTruckDto)
        .then(data => {
          this.addJunkTruck(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Truck! ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJunkTrucks(false)
        })
    },
    setJunkTrucks(junkTrucks) {
      this.junkTrucks = junkTrucks
    },
    async fetchJunkTrucksByOperatingUnitIds(operatingUnitIds) {
      this.setIsLoadingJunkTrucks(true)
      this.setJunkTrucks([])
      this.setJunkTrucksWeeklyAllocations([])

      return await junkApi
        .fetchJunkTrucksByOperatingUnitIds(operatingUnitIds)
        .then(data => {
          if (data === "") this.setJunkTrucks([]) // todo: 204
          else {
            this.setJunkTrucks(data)
            this.setJunkTrucksWeeklyAllocations(data.flatMap(d => d.junkTruckWeeklyAllocationDtos))
          }
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            errorSnackbarWithPositiveAction(`Error Fetching Trucks! ${errorMessageHandler(error)}`, "Retry", () => this.fetchJunkTrucksByOperatingUnitIds(operatingUnitIds))
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJunkTrucks(false)
        })
    },
    setIsLoadingTruckUnavailability(isLoading) {
      this.isLoadingTruckUnavailability = isLoading
    },
    setUnavailableTrucks(unavailableJunkTrucks) {
      this.unavailableTrucks = unavailableJunkTrucks
    },
    async fetchUnavailableTrucksByOperatingUnitIds(operatingUnitIds) {
      this.setIsLoadingTruckUnavailability(true)
      this.setUnavailableTrucks([])
      return await junkApi
        .fetchUnavailableTrucksByOperatingUnitIds(operatingUnitIds)
        .then(data => {
          if (data === "") this.setUnavailableTrucks([]) // todo: 204
          else this.setUnavailableTrucks(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            errorSnackbarWithPositiveAction(`Error fetching unavailable trucks! ${errorMessageHandler(error)}`, "Retry", () => this.fetchUnavailableTrucksByOperatingUnitIds(operatingUnitIds))
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTruckUnavailability(false)
        })
    },
    addJunkTruckUnavailability(unavailableJunkTruck) {
      this.unavailableTrucks = this.unavailableTrucks.filter(jt => {
        return jt.id !== unavailableJunkTruck.id
      })
      this.unavailableTrucks.push(unavailableJunkTruck)
    },
    async createTruckUnavailability(postJunkTruckUnavailabilityDto) {
      this.setIsLoadingTruckUnavailability(true)
      return await junkApi
        .createTruckUnavailability(postJunkTruckUnavailabilityDto)
        .then(data => {
          this.addJunkTruckUnavailability(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error adding truck unavailability! ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTruckUnavailability(false)
        })
    },
    async updateTruckUnavailability(putJunkTruckUnavailabilityDto) {
      this.setIsLoadingTruckUnavailability(true)
      return await junkApi
        .updateTruckUnavailability(putJunkTruckUnavailabilityDto)
        .then(data => {
          this.addJunkTruckUnavailability(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error editing truck unavailability! ${errorMessageHandler(error)}`))
          Sentry.captureException(error)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTruckUnavailability(false)
        })
    },
    removeUnavailableTruck(truckId) {
      let index = this.unavailableTrucks.findIndex(c => c.id === truckId)
      if (~index) {
        this.unavailableTrucks.splice(index, 1)
      }
    },
    async deleteJunkTruckUnavailability(junkTruckUnavailabilityId) {
      this.setIsLoadingTruckUnavailability(true)
      return await junkApi
        .deleteTruckUnavailabilityById(junkTruckUnavailabilityId)
        .then(data => {
          this.removeUnavailableTruck(junkTruckUnavailabilityId)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error deleting truck unavailability! ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTruckUnavailability(false)
        })
    },

    /* Weekly Allocations */
    setJunkTrucksWeeklyAllocations(weeklyAllocations) {
      this.junkTruckWeeklyAllocations = weeklyAllocations // todo: straight setting the list
    },
    setWeeklyAllocation(data) {
      let index = this.junkTruckWeeklyAllocations.findIndex(sa => sa.junkTruckWeeklyAllocationId === data.junkTruckWeeklyAllocationId)
      if (~index) {
        this.junkTruckWeeklyAllocations.splice(index, 1, data)
      } else {
        let idx = this.junkTruckWeeklyAllocations.findIndex(sa => sa.dayOfWeekId === data.dayOfWeekId && sa.junkTruckId === data.junkTruckId)
        this.junkTruckWeeklyAllocations.splice(idx, 1, data)
      }
    },
    async updateWeeklyTruckAllocation(junkTruckWeeklyAllocationDto) {
      this.setIsLoadingJunkTrucks(true)
      return await junkApi
        .updateWeeklyTruckAllocation(junkTruckWeeklyAllocationDto)
        .then(data => {
          this.setWeeklyAllocation(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`Error Updating Truck Allocation! ${errorMessageHandler(error)}`))
          Sentry.captureException(error)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJunkTrucks(false)
        })
    },
    setIsLoadingOperatingUnitAllocationGroups(isLoading) {
      this.isLoadingOperatingUnitAllocationGroups = isLoading
    },
    setOperatingUnitAllocationGroups(operatingUnitAllocationGroups) {
      this.operatingUnitAllocationGroups = operatingUnitAllocationGroups
    },
    addOperatingUnitAllocationGroup(operatingUnitAllocationGroup) {
      this.operatingUnitAllocationGroups.push(operatingUnitAllocationGroup)
    },
    removeOperatingUnitAllocationGroup(id) {
      this.operatingUnitAllocationGroups = this.operatingUnitAllocationGroups.filter(s => {
        return s.operatingUnitAllocationGroupId !== id
      })
    },
    replaceOperatingUnitAllocationGroup(operatingUnitAllocationGroup) {
      let index = this.operatingUnitAllocationGroups.findIndex(o => o.operatingUnitAllocationGroupId === operatingUnitAllocationGroup.operatingUnitAllocationGroupId)
      if (~index) {
        this.operatingUnitAllocationGroups.splice(index, 1, operatingUnitAllocationGroup)
      }
    },
    async fetchOperatingUnitAllocationGroups(operatingUnitId) {
      this.setIsLoadingOperatingUnitAllocationGroups(true)
      return await junkApi
        .fetchOperatingUnitAllocationGroups(operatingUnitId)
        .then(data => {
          this.setOperatingUnitAllocationGroups(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingOperatingUnitAllocationGroups(false)
        })
    },
    async createOperatingUnitAllocationGroup({ operatingUnitId, postOperatingUnitAllocationGroupDto }) {
      this.setIsLoadingOperatingUnitAllocationGroups(true)
      return await junkApi
        .createOperatingUnitAllocationGroup(operatingUnitId, postOperatingUnitAllocationGroupDto)
        .then(data => {
          this.addOperatingUnitAllocationGroup(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingOperatingUnitAllocationGroups(false)
        })
    },
    async deleteOperatingUnitAllocationGroup(operatingUnitAllocationGroupId) {
      this.setIsLoadingOperatingUnitAllocationGroups(true)
      return await junkApi
        .deleteOperatingUnitAllocationGroup(operatingUnitAllocationGroupId)
        .then(data => {
          this.removeOperatingUnitAllocationGroup(operatingUnitAllocationGroupId)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingOperatingUnitAllocationGroups(false)
        })
    },
    async updateOperatingUnitGroupName(dto) {
      this.setIsLoadingOperatingUnitAllocationGroups(true)
      return await junkApi
        .updateOperatingUnitGroupName(dto)
        .then(data => {
          this.replaceOperatingUnitAllocationGroup(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingOperatingUnitAllocationGroups(false)
        })
    },
    async createFranchiseOperatingUnitAllocationGroup(dto) {
      this.setIsLoadingOperatingUnitAllocationGroups(true)
      return await junkApi
        .createFranchiseOperatingUnitAllocationGroup(dto)
        .then(data => {
          this.replaceOperatingUnitAllocationGroup(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingOperatingUnitAllocationGroups(false)
        })
    },
    async deleteFranchiseOperatingUnitAllocationGroup(franchiseOperatingUnitAllocationGroupId) {
      this.setIsLoadingOperatingUnitAllocationGroups(true)
      return await junkApi
        .deleteFranchiseOperatingUnitAllocationGroup(franchiseOperatingUnitAllocationGroupId)
        .then(data => {
          this.replaceOperatingUnitAllocationGroup(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingOperatingUnitAllocationGroups(false)
        })
    },

    /* Cutoff */
    setIsLoadingFranchiseDistanceCutoff(isLoading) {
      this.isLoadingFranchiseDistanceCutoff = isLoading
    },
    setFranchiseCustomerSelfBookingDistanceCutOff(cutOffDistance) {
      const franchise = this.franchisesInOperatingUnit.find(f => f.franchiseId === this.selectedFranchiseObject.franchiseId)
      if (franchise) {
        franchise.selfBookingCutoffDistance = cutOffDistance
      }
      this.selectedFranchiseObject = Object.assign(
        {},
        {
          ...this.selectedFranchiseObject,
          selfBookingCutoffDistance: cutOffDistance
        }
      )
    },
    async putFranchiseCustomerSelfBookingDistanceCutoff(putFranchiseCustomerSelfBookingCutoffDto) {
      this.setIsLoadingFranchiseDistanceCutoff(true)
      return await junkApi
        .putFranchiseCustomerSelfBookingDistanceCutoff(putFranchiseCustomerSelfBookingCutoffDto)
        .then(data => {
          this.setFranchiseCustomerSelfBookingDistanceCutOff(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = errorSnackbar(`${errorMessageHandler(error)}`)
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseDistanceCutoff(false)
        })
    },

    /* Tip Configuration */
    setIsLoadingTipConfiguration(isLoading) {
      this.isLoadingTipConfiguration = isLoading
    },
    setTipConfiguration(tipConfiguration) {
      this.tipConfiguration = Object.assign({}, tipConfiguration)
    },
    updateTipConfiguration(tipConfig) {
      const updatedTipConfiguration = { ...this.getTipConfiguration, ...tipConfig }
      this.setTipConfiguration(updatedTipConfiguration)
    },
    async fetchTipConfigurationByFranchiseId(franchiseId) {
      this.setIsLoadingTipConfiguration(true)
      return await junkApi
        .fetchFranchiseTipConfigurationByFranchiseId(franchiseId)
        .then(data => {
          this.setTipConfiguration(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(
            errorSnackbarWithPositiveAction(`Error Fetching Tip Configuration! ${errorMessageHandler(error)}`, "Retry", () => this.fetchTipConfigurationByFranchiseId(franchiseId))
          )
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTipConfiguration(false)
        })
    },
    async upsertTipConfiguration(tipConfiguration) {
      const snackbarStore = useSnackbarStore()
      this.setIsLoadingTipConfiguration(true)
      return await junkApi
        .upsertFranchiseTipConfiguration(tipConfiguration)
        .then(data => {
          this.setTipConfiguration(data)
          snackbarStore.addSnackbar(successSnackbar("Tip configuration updated!"))
          return Promise.resolve(data)
        })
        .catch(error => {
          snackbarStore.addSnackbar(errorSnackbar(`Error updating tip configuration! ${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingTipConfiguration(false)
        })
    },

    /* Taxes */
    setIsLoadingFranchiseTax(isLoading) {
      this.isLoadingFranchiseTax = isLoading
    },
    setFranchiseTaxes(taxes) {
      this.franchiseTaxes = taxes
    },
    updateFranchiseTax(tax) {
      this.franchiseTaxes = this.franchiseTaxes.filter(ft => {
        return ft.taxId !== tax.taxId
      })
      this.franchiseTaxes.push(tax)
    },
    async fetchFranchiseTaxes(operatingUnitId) {
      this.setIsLoadingFranchiseTax(true)
      return await junkApi
        .fetchFranchiseTaxesByOperatingUnitId(operatingUnitId)
        .then(data => {
          this.setFranchiseTaxes(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseTax(false)
        })
    },
    async createFranchiseTax(franchiseTaxDto) {
      this.setIsLoadingFranchiseTax(true)
      return await junkApi
        .createFranchiseTax(franchiseTaxDto)
        .then(data => {
          this.addFranchiseTax(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseTax(false)
        })
    },
    async endDateFranchiseTax(putFranchiseTaxEndDateDto) {
      this.setIsLoadingFranchiseTax(true)
      return await junkApi
        .putEndDateFranchiseTax(putFranchiseTaxEndDateDto)
        .then(data => {
          this.updateFranchiseTax(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseTax(false)
        })
    },
    async addFranchiseTaxAssociation(associateTaxToFranchisesDto) {
      this.setIsLoadingFranchiseTax(true)
      return await junkApi
        .addFranchiseTaxAssociation(associateTaxToFranchisesDto)
        .then(data => {
          this.updateFranchiseTax(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          snackbarStore.addSnackbar(errorSnackbar(`${errorMessageHandler(error)}`))
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingFranchiseTax(false)
        })
    },
    addFranchiseTax(tax) {
      this.franchiseTaxes.push(tax)
    },

    /* Users */
    async setUsersInOperatingUnits(usersInOperatingUnit) {
      this.usersInOperatingUnit = usersInOperatingUnit
    },
    async setAllRoles(roles) {
      this.roles = roles
    },
    // SET_IS_LOADING_USERS(state, isLoading) { // todo: in OG impl unused
    //   state.isLoadingUsers = isLoading
    // },
    // SET_IS_LOADING_ROLES(state, isLoading) { // todo: in OG impl unused
    //   state.isLoadingRoles = isLoading
    // },
    // SET_IS_LOADING_ROLES_FOR_USER(state, isLoading) { // todo: in OG impl unused
    //   state.isLoadingRolesForUser = isLoading
    // },
  }
})
