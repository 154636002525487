import { defineStore } from "pinia"
import { useSnackbarStore } from "@/stores/Snackbar"
import junkApi from "@/api/Junk"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { errorSnackbar, successSnackbar } from "@/utils/SnackbarBuilder"
import { CustomerTypes } from "@/enums/CustomerTypes"
import { PreferredMethodOfContacts } from "@/enums/PreferredMethodOfContacts"
import { PreferredTimeSlots } from "@/enums/PreferredTimeSlot"
import { TruckCapacitiesList } from "@/enums/TruckCapacities"
import { JobStatuses } from "@/enums/JobStatus"
import getEnv from "@/utils/Env"
import { app, azureauth } from "../../appsettings.json"
import { consoleLog } from "@/utils/Logging";
import {JobTab} from "@/models/JobTab";

export const useMainStore = defineStore("main", {
  state: () => ({
    isLoading: false,
    isSidePanelVisible: false,
    isLoadingAppFullScreen: { isLoading: false, loadingText: "" },
    userActiveFranchises: [],
    customerTypes: CustomerTypes,
    // preferredTimeSlots: PreferredTimeSlots,
    // truckCapacities: TruckCapacitiesList,
    jobStatuses: JobStatuses,
    isLoadingUserActiveFranchises: false,
    isLoadingValidateEin: false,
    operatingUnitMetadataList: [],
    selectedOperatingUnitMetadata: {},
    isSignalRConnected: false,
    jobTabs: [],
    userSignatureProfile: {}
  }),
  getters: {
    getAppEnvironment() {
      return getEnv("VUE_APP_ENVIRONMENT") || app.VUE_APP_ENVIRONMENT
    },
    getJobTabs() {
      return this.jobTabs.slice()
    },
    getAzureTypeExtKey() {
      return getEnv("AZURE_DIRECTORY_TYPE_EXTENSION_KEY") || azureauth.VUE_APP_AZURE_DIRECTORY_TYPE_EXTENSION_KEY
    },
    getAzureOperatingUnitEnvironmentExtKey() {
      return getEnv("AZURE_DIRECTORY_ENVIRONMENT_EXTENSION_KEY") || azureauth.VUE_APP_AZURE_DIRECTORY_ENVIRONMENT_EXTENSION_KEY
    },
    getIsLoading() {
      return this.isLoading
    },
    getIsSignalrConnected() {
      return this.isSignalRConnected
    },
    getIsLoadingAppFullScreen() {
      return this.isLoadingAppFullScreen
    },
    getOperatingUnitMetadataList() {
      return this.operatingUnitMetadataList.slice()
    },
    getUserOperatingUnitIds() {
      return this.operatingUnitMetadataList?.map(ou => ou.id)
    },
    getJobStatusesSortedByName() {
      return this.jobStatuses.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    getCustomerTypes() {
      return CustomerTypes.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    getPreferredMethodOfContacts() {
      return PreferredMethodOfContacts.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    getPreferredTimeSlots() {
      return PreferredTimeSlots.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    getUserActiveFranchises() {
      consoleLog("getUserActiveFranchises.before = ", this.userActiveFranchises)
      let result = this.userActiveFranchises?.slice()?.sort((a, b) => a?.franchiseNameAndNumber?.localeCompare(b?.franchiseNameAndNumber)) ?? []
      consoleLog("getUserActiveFranchises.result = ", result)
      return result
    },
    // getFranchiseEmailByFranchiseId: (state) => { // todo: pinia - update this to be an action
    //   return (franchiseId) => this.userActiveFranchises?.slice().find(franchise => franchise.franchiseId === franchiseId)?.franchiseEmail ?? ""
    //   // return this.userActiveFranchises?.slice().find(franchise => franchise.franchiseId === franchiseId)?.franchiseEmail ?? ""
    // },
    getIsLoadingUserActiveFranchises() {
      return this.isLoadingUserActiveFranchises
    },
    getSelectedOperatingUnit() {
      return this.selectedOperatingUnitMetadata
    },
    getTruckCapacities() {
      // return this.truckCapacities.slice()
      return TruckCapacitiesList.slice()
    },
    getSelectedOperatingUnitId() {
      return this.selectedOperatingUnitMetadata?.id
    },
    getIsSelectedOperatingUnitIdSet() {
      return this.selectedOperatingUnitMetadata?.id !== undefined && this.selectedOperatingUnitMetadata?.id !== null
    },
    getIsLoadingValidateEin() {
      return this.isLoadingValidateEin
    }
  },
  actions: {
    setIsLoading(isLoading) {
      this.isLoading = isLoading
    },
    getFranchiseEmailByFranchiseId(franchiseId) {
      return this.userActiveFranchises?.slice().find(franchise => franchise.franchiseId === franchiseId)?.franchiseEmail ?? ""
    },
    async deleteJobTab(jobId) {
      let idx = this.jobTabs.findIndex(tab => (tab.jobId === jobId))
      if (~idx) {
        this.jobTabs.splice(idx, 1)
      }
    },
    async addJobTab(jobTab) {
      let idx = this.jobTabs.findIndex(tab => (tab.jobId === jobTab.jobId))
      if (~idx) {
        return // Job already in tab list.
      }
      this.jobTabs.push(jobTab)
    },
    async updateJobTabJobNumberByJobId(job) {
      let idx = this.jobTabs.findIndex(tab => tab.jobId === job.id)
      if (~idx) {
        let existingTab = this.jobTabs[idx]
        let tab = new JobTab(job.id, job.jobNumber, existingTab.customerId, existingTab.customerLastName, existingTab.businessName)
        this.jobTabs.splice(idx, 1, tab)
      }
    },
    async updateJobTabCustomerByCustomerId(customerDetails) {
      let copy = [...this.jobTabs]
      copy.forEach((t, i) => {
        if (t.customerId === customerDetails.customerId) {
          let newTab = new JobTab(t.jobId, t.jobNumber, t.customerId, t.customerLastName, customerDetails.businessName)
          this.jobTabs.splice(i, 1, newTab)
        }
      })
    },
    async updateJobTabPrimaryContactByCustomerId(primaryContact) {
      let copy = [...this.jobTabs]
      copy.forEach((t, i) => {
        if (t.customerId === primaryContact.customerId) {
          let newTab = new JobTab(t.jobId, t.jobNumber, t.customerId, primaryContact.lastName, t.businessName)
          this.jobTabs.splice(i, 1, newTab)
        }
      })
    },
    async createOrUpdateEmployeeDetails(dto) {
      return await junkApi
        .upsertEmployeeDetails(dto)
        .then(data => {
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = errorSnackbar(errorMessageHandler(error))
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
    },
    async setSelectedOperatingUnitMetadata(metadata) {
      sessionStorage.setItem("selectedOperatingUnit", JSON.stringify(metadata))
      this.selectedOperatingUnitMetadata = Object.assign({}, metadata)
    },
    async resetSelectedOperatingUnitMetadata() {
      sessionStorage.setItem("selectedOperatingUnit", undefined)
      this.selectedOperatingUnitMetadata = Object.assign({}, undefined)
    },
    async setHubConnectionState(isConnected) {
      this.isSignalRConnected = isConnected
    },
    async setOperatingUnitMetadataList(metadataList) {
      // this.operatingUnitMetadataList = metadataList.slice()
      this.operatingUnitMetadataList = metadataList
    },
    async setIsLoadingAppFullScreen(isLoadingAppFullScreen) {
      this.isLoadingAppFullScreen = { isLoading: isLoadingAppFullScreen.isLoading, loadingText: isLoadingAppFullScreen.loadingText }
    },
    async resetIsLoadingAppFullScreen() {
      this.isLoadingAppFullScreen = { isLoading: false, loadingText: "" }
    },
    async validateEmployerIdentificationNumber(ein) {
      const snackbarStore = useSnackbarStore()
      this.isLoadingValidateEin = true
      return await junkApi
        .validateEmployerIdentificationNumber(ein)
        .then(isValid => {
          isValid ? snackbarStore.addSnackbar(successSnackbar("Charity is valid")) : snackbarStore.addSnackbar(errorSnackbar("Charity is invalid"))
          return Promise.resolve(isValid)
        })
        .catch(error => {
          const snackbar = errorSnackbar(errorMessageHandler(error))
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingValidateEin = false
        })
    },
    async fetchActiveFranchisesForUserByOperatingUnitIds() {
      this.isLoadingUserActiveFranchises = true
      return await junkApi
        .fetchActiveFranchisesByOperatingUnitIds(this.getUserOperatingUnitIds)
        .then(data => {
          this.userActiveFranchises = data
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = errorSnackbar(errorMessageHandler(error))
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingUserActiveFranchises = false
        })
    }
  }
})
