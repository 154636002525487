<template>
  <v-container fluid>
    <base-dialog ref="claim-job-confirmation-dialog-root" :is-dialog-visible="isDialogVisible" :title="title" dialog-image="mdi-calendar-check" @close-dialog="closeDialog">
      <template v-slot:content>
        <v-row dense>
          <v-autocomplete
            ref="claim-job-confirmation-dialog-selected-trucks"
            label="Selected Trucks"
            no-data-text="No Trucks Available"
            v-model="selectedTrucks"
            :items="trucksNotOnJob"
            :item-text="formatTruckName"
            outlined
            multiple
            return-object
            chips
            flat
            solo
            deletable-chips
          />
        </v-row>
        <v-row dense>
          <v-autocomplete
            ref="claim-job-confirmation-dialog-selected-employees"
            label="Selected Employees"
            no-data-text="No Employees Available"
            v-model="mutableEmployeesOnJob"
            :items="sortedEmployeesNotOnJob"
            :item-text="formatEmployeeName"
            return-object
            outlined
            flat
            solo
            multiple
            chips
            deletable-chips
          />
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              ref="claim-job-confirmation-dialog-employee-table"
              :headers="employeeHeaders"
              :items="mutableEmployeesOnJob"
              item-key="employeeId"
              disable-pagination
              disable-sort
              hide-default-footer
            >
              <template v-slot:item.isDriver="{ item }">
                <v-simple-checkbox ref="claim-job-confirmation-dialog-item-driver" color="primary" :value="item.isDriver" @input="updateIsDriver($event, item)" />
              </template>
              <template v-slot:item.employeeName="{ item }">
                <div ref="claim-job-confirmation-dialog-item-employee-name">{{ formatEmployeeName(item) }}</div>
              </template>
            </v-data-table>
          </v-col>
          <v-col>
            <v-data-table
              ref="claim-job-confirmation-dialog-truck-table"
              :headers="truckHeaders"
              :items="trucksOnJobWithSelectedTrucks"
              disable-pagination
              disable-sort
              hide-default-footer
            >
              <template v-slot:item.truckName="{ item }">
                <div ref="claim-job-confirmation-dialog-item-truck-name">{{ formatTruckName(item) }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" color="primaryText" ref="claim-job-confirmation-dialog-cancel-btn" @click="closeDialog" text rounded ripple>Cancel</v-btn>
        <v-btn class="pa-4" color="primary" ref="claim-job-confirmation-dialog-confirm-btn" @click="confirm" :loading="confirmationActionIsLoading" text rounded ripple>
          Confirm
        </v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"

export default {
  name: "ClaimJobConfirmationDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    confirmationActionIsLoading: {
      type: Boolean,
      default: false
    },
    selectedTruckId: {
      type: Number
    },
    employeesOnJob: {
      type: Array,
      default: () => []
    },
    employeesNotOnJob: {
      type: Array,
      default: () => []
    },
    trucksOnJob: {
      type: Array,
      default: () => []
    },
    trucksNotOnJob: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      mutableEmployeesOnJob: [],
      selectedTrucks: []
    }
  },
  methods: {
    updateIsDriver(event, item) {
      let matchingIndex = this.mutableEmployeesOnJob.findIndex(d => d.employeeId === item.employeeId)

      if (~matchingIndex) {
        let matchingRecord = this.mutableEmployeesOnJob[matchingIndex]
        matchingRecord.isDriver = event
        this.mutableEmployeesOnJob.splice(matchingIndex, 1, matchingRecord)
      } else {
        item.isDriver = event
        this.mutableEmployeesOnJob.push(item)
      }
    },
    confirm() {
      let mappedEmployeeIds = this.mutableEmployeesOnJob.map(employee => employee.employeeId)
      let selectedDrivers = this.mutableEmployeesOnJob.slice().filter(employee => employee.isDriver).map(employee => employee.employeeId)
      let selectedTruckIds = this.selectedTrucks.map(t => t.junkTruckId)
      this.$emit("confirm", { employeeIds: mappedEmployeeIds, truckIds: selectedTruckIds, drivers: selectedDrivers })
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    formatTruckName(item) {
      return item.isUnavailable ? `${item.name} (unavailable)` : `${item.name}`
    },
    formatEmployeeName(item) {
      return `${item.lastName}, ${item.firstName}`
    },
    setMutableEmployeesOnJob(employeesOnJob) {
      this.mutableEmployeesOnJob = JSON.parse(JSON.stringify(employeesOnJob))
    }
  },
  computed: {
    trucksOnJobWithSelectedTrucks() {
      return this.trucksOnJob.concat(this.selectedTrucks)
    },
    employeeHeaders() {
      return [
        { text: "Employees on Job", align: "start", value: "employeeName", class: "secondaryDark white--text" },
        { text: "Driver", align: "start", value: "isDriver", class: "secondaryDark white--text" }
      ]
    },
    truckHeaders() {
      return [{ text: "Trucks on Job", align: "start", value: "truckName", class: "secondaryDark white--text" }]
    },
    sortedEmployeesNotOnJob() {
      return this.employeesNotOnJob
        .slice()
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map(employee => {
          return {
            employeeId: employee.id,
            firstName: employee.givenName,
            lastName: employee.surname,
            jobEmployeeId: null,
            isDriver: false
          }
        })
    }
  },
  created() {
    if (this.selectedTruckId !== null && this.selectedTruckId !== undefined) this.selectedTrucks.push(this.selectedTruckId)
    this.setMutableEmployeesOnJob(this.employeesOnJob)
  }
}
</script>
